<template>
    <div data-view="dolo.history.v0.12020">
        This is the History View
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data: () => {
        return {

        }
    }
}
</script>