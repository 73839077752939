<template>
    <div data-view="dolo.about.v0.12020" class="content-inner max white">
        <div class="max hidden-xs-only">
            <div class="co-sec1">
                <v-layout fill-height align-end>
                    <div class="abs1-title dolo font-weight-black white--text">
                        Say hello.
                    </div>
                </v-layout>
            </div>
            <div class="co-sec2 black">
                <v-container>
                    <v-layout>
                        <v-flex xs12 md4>
                            <v-card flat color="transparent">
                                <v-card-title class="px-0">
                                    <v-card flat color="white" height="1" width="250"></v-card>
                                </v-card-title>
                                <v-card-text class="dolo t20 white--text font-weight-black px-0" style="">
                                    Hi.
                                </v-card-text>
                            </v-card>
                        </v-flex>
                        <v-flex xs12 md8 class="right-section pr-0">
                            <div class="abs2-title lulu mb-12 white--text">
                                We're here for you. So say hey.
                            </div>
                            <div mt-12>
                                <v-layout row wrap>
                                    <v-flex xs12 md6 v-for="(c, i) in contacts" :key="i" style="margin-bottom: 90px;">
                                        <v-card color="transparent">
                                            <v-card-title class="dolo white--text">
                                                {{c.header}}
                                            </v-card-title>
                                            <v-card-text>
                                                <!-- <div style="padding-top: 40px" class="dolo number white--text mb-4">{{c.number}}</div> -->
                                                <div class="lulu email white--text">{{c.email}}</div>
                                            </v-card-text>
                                        </v-card>
                                    </v-flex>
                                </v-layout>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-container>
            </div>
        </div>
        <div class="mobi hidden-sm-and-up">
            <div class="co-sec1">
                <v-layout fill-height align-end>
                    <div class="abs1-title dolo font-weight-black white--text">
                        Say hello.
                    </div>
                </v-layout>
            </div>
            <div class="co-sec2 black">
                <v-container>
                    <v-layout row wrap>
                        <v-flex xs12 pl-8 mb-6>
                            <v-card flat color="transparent">
                                <v-card-title class="px-0">
                                    <v-card flat color="white" height="1" width="150"></v-card>
                                </v-card-title>
                                <v-card-text class="dolo t20 white--text font-weight-black px-0" style="">
                                    Hi.
                                </v-card-text>
                            </v-card>
                        </v-flex>
                        <v-flex xs12 md8 class="right-section pr-0">
                            <div class="abs2-title lulu mb-12 white--text pr-8">
                                We're here for you. So say hey.
                            </div>
                            <div mt-12>
                                <v-layout row wrap>
                                    <v-flex xs12 md6 v-for="(c, i) in contacts" :key="i" style="margin-bottom: 45px;">
                                        <v-card color="transparent">
                                            <v-card-title class="t20 lulu white--text">
                                                {{c.header}}
                                            </v-card-title>
                                            <v-card-text>
                                                <div style="padding-top: 10px" class="dolo number white--text mb-4">{{c.number}}</div>
                                                <div class="lulu email white--text">{{c.email}}</div>
                                            </v-card-text>
                                        </v-card>
                                    </v-flex>
                                </v-layout>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-container>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components: {
    },
    props: {},
    data: () => {
        return {
            contacts: [
                {
                    header: 'Become a client.',
                    number: '940.536.7638',
                    email: 'business@dololabs.com',
                },
                {
                    header: 'Media Inquiries.',
                    number: '940.775.3656',
                    email: 'media@dololabs.com',
                },
                {
                    header: 'Need Support.',
                    number: '940.775.3656',
                    email: 'support@dololabs.com',
                },
                {
                    header: 'Join DoloLabs.',
                    number: '214.394.2167',
                    email: 'work@dololabs.com',
                },
                {
                    header: 'All the other stuff.',
                    number: '800.547.3656',
                    email: 'hi@dololabs.com',
                },
            ]
        }
    },
    methods: {
        
    }
}
</script>

<style lang="scss" scoped>
    .co-sec1 {
        height: 75vh;
        background-image: url("https://onstuimig-adfactorbe.s3.amazonaws.com/images/uploads/blog/_blog-old/WEBSITE_HEADER_CONTACT.jpg");
        background-size: cover;
        background-color: #000;
        background-position-y: 0%;
        opacity: 1;

        .abs1-title {
            width: 50%;
            margin-left: 8%;
            margin-bottom: 2.5%;
            font-size: 12em;
            letter-spacing: -8px;
            line-height: 180px
        }
    }

    .co-sec2 {
        padding: 125px 0;

        .right-section {
            padding: 0 8% 
        }

        .abs2-title {
            font-size: 3.5em;
            line-height: 70px;
            word-wrap: break-word;
        }

        .number {
                font-size: 24px;
            }

            .email{
                font-size: 20px;
            }
    }

    .tablet {
        .co-sec1 {
            height: 75vh;
            background-image: url("https://onstuimig-adfactorbe.s3.amazonaws.com/images/uploads/blog/_blog-old/WEBSITE_HEADER_CONTACT.jpg");
            background-size: cover;
            background-color: #000;
            background-position-y: 0%;
            opacity: 1;

            .abs1-title {
                width: 50%;
                margin-left: 8%;
                margin-bottom: 2.5%;
                font-size: 8em;
                letter-spacing: -8px;
                line-height: 100px
            }
        }

        .co-sec2 {
            padding: 125px 0;

            .right-section {
                padding: 0 8% 
            }

            .abs2-title {
                font-size: 2.5em;
                line-height: 70px;
                word-wrap: break-word;
            }

            .number {
                font-size: 18px;
            }

            .email{
                font-size: 18px;
            }
        }
    }

    .mobi {
        .co-sec1 {
            height: 75vh;
            background-image: url("https://onstuimig-adfactorbe.s3.amazonaws.com/images/uploads/blog/_blog-old/WEBSITE_HEADER_CONTACT.jpg");
            background-size: cover;
            background-color: #000;
            background-position-y: 0%;
            background-position-x: 50%;
            opacity: 1;

            .abs1-title {
                width: 100%;
                margin-left: 5%;
                margin-bottom: 8.5%;
                font-size: 4em;
                letter-spacing: -2.5px;
                line-height: 60px
            }
        }

        .co-sec2 {
            padding: 25px 0;

            .abs2-title {
                font-size: 1.5em;
                line-height: 30px;
                word-wrap: break-word;
            }

            .number {
                    font-size: 18px;
                }

                .email{
                    font-size: 14px;
                }
        }
    }
</style>