<template>
    <div class="mobi-nav-menu black" data-component="dolo.mobi-nav-menu.v0.12020">
        <v-layout row wrap justify-center align-center>
            <v-flex xs12 v-for="(n, i) in navMenu" :key="i" >
                <v-card flat color="transparent" class="nav-item mx-8 mb-2" :to="{name: n.to}" @click="closeMenu(n.to)" @mouseover.native="previewRoute(n.to)">
                    <v-card-title class="n-title font-weight-black justify-center py-0 dolo white--text" style="letter-spacing: -0px;">{{n.title}}.</v-card-title>
                    <!-- <v-card-text class="n-subtitle text-center lulu py-0">{{n.subtitle}}</v-card-text> -->
                    <v-card-text class="py-2">
                    <v-layout justify-center>
                        <v-card flat height="1" width="30px" color="cyan"></v-card>
                    </v-layout>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
        <mobi-footer />
    </div>
</template>

<script>
//import router from 'vue-router'
import mobiFooter from './mobi-footer'

export default {
    name: 'nav-menu',
    components: {
        mobiFooter
    },
    props: {},
    data: () => {
        return {
            navMenu : [
                {
                    title: 'Hello',
                    subtitle: 'What we are',
                    color: 'tertiary',
                    to: 'home'
                },
                {
                    title: 'About',
                    subtitle: 'Who we are',
                    color: 'tertiary',
                    to: 'about'
                },
                {
                    title: 'Products',
                    subtitle: 'What we\'ve made',
                    color: 'tertiary',
                    to: 'products'
                },
                /* {
                    title: 'History',
                    subtitle: 'Where we\'ve been',
                    color: 'tertiary',
                    to: 'history'
                }, */
                {
                    title: 'Careers',
                    subtitle: 'Join the team',
                    color: 'tertiary',
                    to: 'careers'
                },
                {
                    title: 'Contact',
                    subtitle: 'Get in touch',
                    color: 'tertiary',
                    to: 'contact'
                }
            ]
        }
    },
    methods: {
        closeMenu(r){
            this.$emit('closeMenu', r)
            this.$emit('homeSection')
            //eslint-disable-next-line
            //console.log('nav-menu.methods.closeMenu -- ' +r)
        },
        previewRoute(r){
            this.$emit('previewRoute', r)
            //eslint-disable-next-line
            //console.log('nav-menu.methods.previewRoute -- emitted '+r)
        }
    }
}
</script>

<style lang="scss" scoped>
    .mobi-nav-menu {
        position: fixed;
        height: 100vh;
        width: 100%;
        top: 0;
        left: 0;
        padding-top: 30%;
        background-color: #f3f3f3;

        .nav-item {
            .n-title {
                color: black;
                letter-spacing: -55px;
                font-size: 20px;
            }
            .n-subtitle {
                font-size: .575rem
            }

            &:hover {
                .n-title {
                    color: #E91E63
                }
            }
        }
    }
</style>