<template>
    <div data-view="dolo.product-overview.v0.12020" class="max">
        <div class="hidden-xs-only">
            <div class="prod-sec1" :class="prod.color1">
                <div class="prod-breadcrum">
                    <v-layout align-center fill-height @click="killDialog()">
                        <span class="dolo t16 white--text" style="cursor: pointer;">Products</span>
                        <span class="mx-3 pt-1">
                            <v-card flat height="1" width="180" color="white"></v-card>
                        </span>
                        <span class="dolo t16 white--text">{{prod.title}}</span>
                    </v-layout>
                </div>
                <div class="prod-img">
                    <v-img
                        :src="prod.img"
                        :lazy-src="prod.img"
                        aspect-ratio="1"
                    ></v-img>
                </div>
                <v-layout fill-height align-end>
                    <div class="prod-title">
                        <div class="prod-name dolo white--text">
                            {{prod.title}}<span :class="prod.color2+'--text'">.</span>
                        </div>
                        <div class="prod-pos lulu white--text">
                            {{prod.released}}
                        </div>
                        <div class="prod-social">
                            <v-btn fab outlined color="white" class="mr-3" :href="prod.twitter">
                                <i class="lab la-twitter" style="font-size: 24px"></i>
                            </v-btn>
                            <v-btn fab outlined color="white" class="mr-3" :href="prod.linkedin">
                                <i class="lab la-linkedin-in" style="font-size: 24px"></i>
                            </v-btn>
                            <v-btn fab outlined color="white" class="mr-3" :href="prod.website" target="_blank">
                                <i class="las la-laptop-code" style="font-size: 24px"></i>
                            </v-btn>
                        </div>
                    </div>
                </v-layout>
            </div>
            <div class="prod-sec2 white black--text">
                <v-container>
                    <v-layout>
                        <v-flex xs12 md4>
                            <v-layout row wrap justify-center>
                                <v-card flat width="250" color="transparent">
                                    <v-card-title>
                                        <v-card flat color="black" height="1" width="250"></v-card>
                                    </v-card-title>
                                    <v-card-text class="dolo black--text t20 font-weight-black" style="">
                                        Overview.
                                    </v-card-text>
                                </v-card>
                            </v-layout>
                        </v-flex>
                        <v-flex xs12 md8 class="right-section pr-0">
                            <div class="cs2-title lulu mb-12" v-if="prod.tagline">
                                {{prod.tagline}}
                            </div>
                            <div class="t20 lulu" style="line-height: 41.5px">
                                {{prod.overview}}
                            </div>
                            <v-btn class="dolo mt-8 ml-n4" text :color="prod.color2" :href="prod.website" target="_blank">
                                <v-layout fill-height align-center>
                                    Check it out
                                    <i class="las la-arrow-right t22 ml-4 mt-n1"></i>
                                </v-layout>
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-container>
            </div>
        </div>
        <div class="hidden-sm-and-up mobi">
            <div class="prod-sec1" :class="prod.color1">
                <div class="prod-breadcrum text-center">
                    <v-btn text class="t24 white--text" @click="killDialog()">
                        <i class="las la-times"></i>
                    </v-btn>
                </div>
                <div class="prod-img">
                    <v-img
                        :src="prod.img"
                        :lazy-src="prod.img"
                        aspect-ratio="1"
                    ></v-img>
                </div>
                <v-layout fill-height align-end>
                    <div class="prod-title">
                        <div class="prod-name dolo white--text">
                            {{prod.title}}<span :class="prod.color2+'--text'">.</span>
                        </div>
                        <div class="prod-pos lulu white--text">
                            {{prod.released}}
                        </div>
                        <div class="prod-social mt-n2">
                            <v-btn fab outlined x-small color="white" class="mr-3" :href="prod.twitter">
                                <i class="lab la-twitter" style="font-size: 12px"></i>
                            </v-btn>
                            <v-btn fab outlined x-small color="white" class="mr-3" :href="prod.linkedin">
                                <i class="lab la-linkedin-in" style="font-size: 12px"></i>
                            </v-btn>
                            <v-btn fab outlined x-small color="white" class="mr-3" :href="prod.website" target="_blank">
                                <i class="las la-laptop-code" style="font-size: 12px"></i>
                            </v-btn>
                        </div>
                    </div>
                </v-layout>
            </div>
            <div class="prod-sec2 white black--text">
                <v-container>
                    <v-layout row wrap>
                        <v-flex xs12 mb-6>
                            <v-layout row wrap pl-4>
                                <v-card flat color="transparent">
                                    <v-card-title>
                                        <v-card flat color="black" height="1" width="150"></v-card>
                                    </v-card-title>
                                    <v-card-text class="dolo black--text t20 font-weight-black" style="">
                                        Overview.
                                    </v-card-text>
                                </v-card>
                            </v-layout>
                        </v-flex>
                        <v-flex xs12 md8 class="right-section px-5">
                            <div class="cs2-title lulu mb-12" v-if="prod.tagline">
                                {{prod.tagline}}
                            </div>
                            <div class="t14 lulu" style="line-height: 31.5px">
                                {{prod.overview}}
                            </div>
                            <v-btn class="dolo mt-8 ml-n2" text :color="prod.color2" :href="prod.website" target="_blank">
                                <v-layout fill-height align-center>
                                    Check it out
                                    <i class="las la-arrow-right t22 ml-4 mt-n1"></i>
                                </v-layout>
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-container>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        prod: Object,
    },
    data: () => {
        return {

        }
    },
    methods: {
        killDialog(){
            this.$emit('killProdDetDialog')
            //eslint-disable-next-line
            //console.log('product-details.methods.killDialog -- fired')
        }
    }
}
</script>

<style lang="scss" scoped>
    .prod-sec1 {
        height: 75vh;
        /* background: rgb(239,239,239);
        background: linear-gradient(331deg, rgba(239,239,239,1) 8%, rgba(146,148,154,1) 100%); */
        /* background: rgb(173,20,87);
        background: linear-gradient(331deg, rgba(173,20,87,1) 0%, rgba(26,35,126,1) 100%); */
        overflow: hidden;

        .prod-img {
            position: absolute;
            left: 45%;
            height: 65%;
            width: 40%;
            top: 10%;
        }

        .prod-title {
            width: 60%;
            margin-left: 8%;
            margin-bottom: 2.5%;

            .prod-name {
                font-size: 12em;
                letter-spacing: -8px;
            }
            .prod-pos {
                font-size: 1.5em;
                margin: -60px 0 20px;
            }
        }

        .prod-breadcrum{
            position: absolute;
            left: 8%;
            top: 2%;
            height: 50px;
        }
    }

    .prod-sec2 {
        padding: 125px 0;

        .right-section {
            padding: 0 0% 
        }

        .abs2-title {
            font-size: 3.5em;
            line-height: 70px;
            word-wrap: break-word;
        }
    }

    .cs2-title {
        font-size: 2.5em;
        line-height: 35px;
        word-wrap: break-word;
    }

    .tablet {
        .prod-sec1 {
            height: 75vh;
            overflow: hidden;

            .prod-img {
                position: absolute;
                left: 45%;
                height: 65%;
                width: 40%;
                top: 10%;
            }

            .prod-title {
                width: 60%;
                margin-left: 8%;
                margin-bottom: 2.5%;

                .prod-name {
                    font-size: 7em;
                    letter-spacing: -8px;
                }
                .prod-pos {
                    font-size: 1em;
                    margin: -40px 0 20px;
                }
            }

            .prod-breadcrum{
                position: absolute;
                left: 8%;
                top: 2%;
                height: 50px;
            }
        }

        .prod-sec2 {
            padding: 125px 0;

            .right-section {
                padding: 0 0% 
            }

            .abs2-title {
                font-size: 3.5em;
                line-height: 70px;
                word-wrap: break-word;
            }
        }

        .cs2-title {
            font-size: 2.5em;
            line-height: 35px;
            word-wrap: break-word;
        }
    }

    .mobi {
        .prod-sec1 {
            height: 75vh;
            overflow: hidden;

            .prod-img {
                display: none;
            }

            .prod-title {
                width: 100%;
                margin-left: 4%;
                margin-bottom: 8.5%;

                .prod-name {
                    font-size: 3.5em;
                    letter-spacing: -2.5px;
                }
                .prod-pos {
                    font-size: 12px;
                    margin: -10px 0 20px;
                }
            }

            .prod-breadcrum{
                position: absolute;
                left: 0%;
                top: 2%;
                height: 50px;
                width: 100%;
            }
        }

        .prod-sec2 {
            padding: 25px 0;

            .right-section {
                padding: 0 0% 
            }

            .abs2-title {
                font-size: 1.5em;
                line-height: 40px;
                word-wrap: break-word;
            }
        }

        .cs2-title {
            font-size: 2em;
            line-height: 35px;
            word-wrap: break-word;
        }
    }
</style>