<template>
  <v-app class="max" :class="tablet ? 'tablet' : mini ? 'tablet mini' : ''">
    <v-content class="hidden-xs-only max">
      <div v-if="deving == true" class="max black">
        <div row wrap fill-height justify-center align-center class="dolo cs text-center white--text">
          <v-flex xs12>
            <v-card height="10" width="200" color="secondary"></v-card>
          </v-flex>
          <v-flex xs12>
            Dololabs <span class="tertiary--text" @click="deving = false">.</span>
          </v-flex>
        </div>
      </div>
      <div class="max" v-else>
        <nav-menu @closeMenu="navSelect" @previewRoute="previewRoute" @homeSection="resetHome()"/>
        <div class="dolo-btn" @click="toggleMenu()">
          <v-layout justify-center align-center fill-height>
            <v-card
              flat
              class="logo"
              @mouseover="hoverMenu = true"
              @mouseleave="hoverMenu = false"
              :class="openMenu ? 'transparent' : hoverMenu && !openMenu ? 'black' : 'white'"
              :width="openMenu ? 250 : 75" height="70"
            >
              <v-layout justify-center align-center fill-height>
                <div v-if="!openMenu" class="t40 font-weight-black dolo mt-2" :class="hoverMenu ? 'tertiary--text' : 'black--text'">
                  <i class="las la-bars"></i>
                </div>
                <div v-else class="t40 font-weight-black dolo mt-2 tertiary--text">
                  <i class="las la-times"></i>
                </div>
                <!-- <v-icon :color="hoverMenu ? 'amber' : 'teal'" v-if="!openMenu" style="transform: rotate(170deg)" size="40">bubble_chart</v-icon> -->
                <!-- <div class="max text-uppercase t40 dolo" v-else>
                  <span class="cyan--text">Dolo</span>
                  <span class="indigo--text font-weight-light roboto">Labs</span>
                </div> -->
              </v-layout>
            </v-card>
          </v-layout>
        </div>
        <div class="mca" :class="[hoverMenu ? 'hovered' : '', openMenu ? 'opened' : '']" @click="closeMenu()">
          <div class="content-window">
            <div class="max" v-if="hoverRoute != null">
              <home v-if="hoverRoute == 'home'" />
              <about v-if="hoverRoute == 'about'" />
              <products v-if="hoverRoute == 'products'" />
              <history v-if="hoverRoute == 'history'" />
              <careers v-if="hoverRoute == 'careers'" />
              <contact v-if="hoverRoute == 'contact'" />
            </div>
            <router-view v-else></router-view>
            <dolo-footer />
          </div>
        </div>
      </div>
    </v-content>
    <v-content class="mobi max hidden-sm-and-up">
      <div v-if="deving == true" class="max black">
        <div row wrap fill-height justify-center align-center class="dolo cs text-center cyan--text">
          <v-flex xs12>
            <v-card height="10" width="200" color="indigo"></v-card>
          </v-flex>
          <v-flex xs12>
            Dololabs <span class="pink--text" @click="deving = false">.</span>
          </v-flex>
        </div>
      </div>
      <div class="max" v-else>
        <mobi-nav-menu @closeMenu="navSelect" @previewRoute="previewRoute" @homeSection="resetHome()"/>
        <div class="dolo-btn" @click="toggleMenu()">
          <v-layout justify-center align-center fill-height>
            <v-card
              flat
              class="logo"
              @mouseover="hoverMenu = true"
              @mouseleave="hoverMenu = false"
              :class="openMenu ? 'transparent' : hoverMenu && !openMenu ? 'black' : 'white'"
              :width="openMenu ? 250 : 75" height="70"
            >
              <v-layout justify-center align-center fill-height>
                <div v-if="!openMenu" class="t40 font-weight-black dolo mt-2" :class="hoverMenu ? 'cyan--text' : 'black--text'">
                  <i class="las la-bars"></i>
                </div>
                <div v-else class="t40 font-weight-black dolo mt-2 pink--text">
                  <i class="las la-times"></i>
                </div>
                <!-- <v-icon :color="hoverMenu ? 'amber' : 'teal'" v-if="!openMenu" style="transform: rotate(170deg)" size="40">bubble_chart</v-icon> -->
                <!-- <div class="max text-uppercase t40 dolo" v-else>
                  <span class="cyan--text">Dolo</span>
                  <span class="indigo--text font-weight-light roboto">Labs</span>
                </div> -->
              </v-layout>
            </v-card>
          </v-layout>
        </div>
        <div class="mca" :class="[hoverMenu ? 'hovered' : '', openMenu ? 'opened' : '']" @click="closeMenu()">
          <div class="content-window">
            <div class="max" v-if="hoverRoute != null">
              <home v-if="hoverRoute == 'home'" />
              <about v-if="hoverRoute == 'about'" />
              <products v-if="hoverRoute == 'products'" />
              <history v-if="hoverRoute == 'history'" />
              <careers v-if="hoverRoute == 'careers'" />
              <contact v-if="hoverRoute == 'contact'" />
            </div>
            <router-view v-else></router-view>
          </div>
        </div>
      </div>
    </v-content>
  </v-app>
</template>

<script>
import navMenu from '../src/components/nav-menu'
import mobiNavMenu from '../src/components/mobile/mobi-nav-menu'
import home from '../src/views/Home'
import about from '../src/views/About'
import products from '../src/views/Products'
import history from '../src/views/History'
import careers from '../src/views/Careers'
import contact from '../src/views/Contact'
import doloFooter from './components/dolo-footer'

export default {
  name: 'App',
  components: {
    navMenu,
    mobiNavMenu,
    home,
    about,
    products,
    history,
    careers,
    contact,
    doloFooter
  },
  data: () => ({
    hoverMenu : false,
    openMenu: false,
    navColor: 'transparent',
    navIconColor: 'teal',
    curRoute: '',
    hoverRoute: null,
    home: 0,
    tablet: false,
    mini: false,
    deving: true,
  }),
  mounted(){
    const a = window.innerWidth
    if(a <= 1024) {
      //const b = '80%'
      //document.body.style.zoom = b
      this.tablet = true
      this.mini = true
      //eslint-disable-next-line
      console.log('app.mounted -- get size < 1400 and zoomout to --'+ b)
    } else if (a < 1400) {
      //this.tablet = true
      //const b = '90%'
      //document.body.style.zoom = b
      //eslint-disable-next-line
      console.log('app.mounted -- get size < 2000 and zoomout to --'+ b)
    }
  },
  methods: {
    toggleMenu() {
      if(this.openMenu == true) {
        this.openMenu = !this.openMenu,
        this.hoverMenu = false
        //eslint-disable-next-line
        //console.log('app.methods.openMenu -- '+this.openMenu)
      } else {
        this.openMenu = true,
        this.hoverMenu = false
        //eslint-disable-next-line
        //console.log('app.methods.openMenu --' +this.openMenu)
      }
    },
    closeMenu() {
      if(this.openMenu  == true) {
        this.openMenu = false
      }
    },
    navSelect(r) {
      this.curRoute = r
      this.openMenu = false
      //eslint-disable-next-line
      console.log('app.methods.navSelect -- ' +r)
    },
    hoverBtn() {
      this.hoverMenu = true
      //eslint-disable-next-line
      alert('hovered and should set to : true : '+this.hoverMenu)
    },
    previewRoute(r){
      this.hoverRoute = r
    },
    resetHome(){
      this.home = 0
    }
  }
}
</script>

<style lang="scss">
  body > div {
    transform: translate3d(0,0,0);
    -webkit-transform: translate3d(0,0,0);
    -webkit-overflow-scrolling: touch;
  }

  .cs {
    position: fixed;
    left: 10%;
    bottom: 10%;
    font-size: 10em;
    letter-spacing: -10px;
  }

  .tablet {
    .cs {
      font-size: 8.5em;
      letter-spacing: -2.5px;
    }
  }

  .mobi {
    .cs {
      font-size: 4.5em;
      letter-spacing: -2.5px;
    }
  }

  #app {
    height: 100vh
  }

  .max {
    height: 100%;
    width: 100%;
  }

  .dolo-btn {
    position: fixed;
    top: 4vh;
    width: 300px;
    height: 120px;
    left: calc(45% - 80px);
    z-index: 10;
    transition: all .84s;


    &:hover {
      color: #FFFFFF;
      cursor: pointer;
    }

    .logo {
      position: absolute;
      z-index: 1;
      width: 100%;
    }

    .dolo-text {
      position: absolute;
      width: 100%;
      z-index: 0;
      top: .5vh;
    }

  }

  .tablet {
    .dolo-btn {
      position: fixed;
      top: 4vh;
      width: 70px;
      height: 70px;
      left: calc(50% - 45px);
      z-index: 10;
      transition: all .84s;


      &:hover {
        color: #FFFFFF;
        cursor: pointer;
      }

      .logo {
        position: absolute;
        z-index: 1;
        width: 100%;
      }

      .dolo-text {
        position: absolute;
        width: 100%;
        z-index: 0;
        top: .5vh;
      }

    }
  }

  .mca {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;

    .content-window {
      position: absolute;
      height: 100vh;
      width: 100%;
      top: 0;
      left: 0;
      transition: all .52s;

      .content-inner {
        height: 100vh;
        transition: all .52s;
        overflow-y: scroll;
      }
    }

    &.hovered {
      .content-window {
        overflow: hidden;
        height: 90vh;
        top: 100px;
        width: 90%;
        left: 5%;
      }

      .content-inner{
        transform: scale(1);
        transition: all .52s;
      }
    }

    &.opened {
      height: 70%;
      top: 30%;
      width: 90%;
      left: 5%;
      overflow: hidden;
      
    }
  }

  // mobile stuff
  .mobi {
    .dolo-btn {
      position: fixed;
      top: 4vh;
      width: 70px;
      height: 70px;
      left: calc(50% - 35px);
      z-index: 10;
      transition: all .84s;


      &:hover {
        color: #FFFFFF;
        cursor: pointer;
      }

      .logo {
        position: absolute;
        z-index: 1;
        width: 100%;
      }

      .dolo-text {
        position: absolute;
        width: 100%;
        z-index: 0;
        top: .5vh;
      }

    }

    .mca {
      position: fixed;
      height: 100vh;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 1;

      .content-window {
        position: absolute;
        height: 100vh;
        width: 100%;
        top: 0;
        left: 0;
        transition: all .52s;

        .content-inner {
          height: 100vh;
          transition: all .52s;
          overflow-y: scroll;
        }
      }

      &.hovered {
        .content-window {
          overflow: hidden;
          height: 90vh;
          top: 100px;
          width: 90%;
          left: 5%;
        }

        .content-inner{
          transform: scale(1);
          transition: all .52s;
        }
      }

      &.opened {
        height: 10%;
        top: 90%;
        width: 90%;
        left: 5%;
        overflow: hidden;
        
      }
    }
  }
</style>
