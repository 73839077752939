<template>
    <div data-view="dolo.about.v0.12020" class="content-inner max white">
        <div class="po-sec1 black">
            <v-layout fill-height align-end>
                <div class="pos1-title dolo font-weight-black black--text">
                    Build your passion
                </div>
            </v-layout>
        </div>
        <div class="po-sec2 black">
            <v-container fluid>
                <v-layout row wrap>
                    <v-flex xs12 class="right-section pr-0">
                        <div mt-12>
                            <v-layout row wrap pa-2 justify-center>
                                <v-flex xs12 md4 pa-2 ma-0 v-for="(p, i) in products" :key="i" style="margin-bottom: 90px;">
                                    <v-hover v-slot:default="{ hover }" class="hidden-xs-only">
                                        <v-card class="prod-card overflow-hidden" flat tile :color="hover ? 'black' : 'white'" height="550px" :class="hover ? 'active' : ''" @click="openProdDets(p)">
                                            <div class="prod-ft">
                                                <div class="prod-title dolo" :class="hover ? 'white--text' : 'black--text'">
                                                    <div class="mb-3">
                                                        <v-card flat height="3" width="50px" :color="p.color1"></v-card>
                                                    </div>
                                                    <div>
                                                    {{p.title}}<span :class="p.color2+'--text'">.</span>
                                                    </div>
                                                </div>
                                                <div class="prod-desc lulu white--text t20">
                                                    {{p.desc}}
                                                </div>
                                                <div class="prod-released dolo t16" :class="p.color2+'--text'">
                                                    {{p.released}}
                                                </div>
                                                <div class="prod-btn">
                                                    <i class="las la-arrow-right white--text" style="font-size: 64px;"></i>
                                                </div>
                                            </div>
                                        </v-card>
                                    </v-hover>
                                    <v-hover v-slot:default="{ hover }" class="hidden-sm-and-up">
                                        <v-card v-on="hover" class="prod-card overflow-hidden mx-2 active" flat tile color="black" height="350px" @click="openProdDets(p)">
                                            <div class="prod-ft">
                                                <div class="prod-title dolo white--text">
                                                    <div class="mb-3">
                                                        <v-card flat height="3" width="50px" :color="p.color1"></v-card>
                                                    </div>
                                                    <div>
                                                    {{p.title}}<span :class="p.color2+'--text'">.</span>
                                                    </div>
                                                </div>
                                                <div class="prod-desc lulu white--text t12">
                                                    {{p.desc}}
                                                </div>
                                                <div class="prod-released dolo t10" :class="p.color2+'--text'">
                                                    {{p.released}}
                                                </div>
                                                <div class="prod-btn">
                                                    <i class="las la-arrow-right white--text" style="font-size: 34px;"></i>
                                                </div>
                                            </div>
                                        </v-card>
                                    </v-hover>
                                </v-flex>
                            </v-layout>
                        </div>
                    </v-flex>
                </v-layout>
            </v-container>
        </div>
        <!-- <div class="co-prod" v-for="(p,i) in products" :key="i">
            <div class="prod ">
                <div class="prod-info">
                    <v-card flat height="4" width="10%" color="lime"></v-card>
                    <div class="prod-title dolo black--text">
                        {{p.title}}
                        <span class="regi ml-n10">®</span>
                    </div>
                    <div class="prod-desc lulu t24">
                        {{p.desc}}
                    </div>
                    <div class="prod-released dolo">
                        {{p.released}}
                    </div>
                    <div class="pl-6 mt-8">
                        <v-btn large icon class="cyan--text" style="font-size: 64px;">
                            <i class="las la-arrow-right"></i>
                        </v-btn>
                    </div>
                </div>
                <div class="prod-visual" :class="p.color">
                    <div class="prod-img">
                        <v-img
                            :src="p.img"
                            :lazy-src="p.img"
                            contain
                        ></v-img>
                    </div>
                </div>
            </div>
        </div>
        <div class="co-prod black">
            <div class="prod bgGrey">
                <div class="prod-info">
                    <v-card flat height="2" width="10%" color="amber"></v-card>
                    <div class="prod-title dolo">
                        Strategery
                        <span class="regi ml-n10">®</span>
                    </div>
                    <div class="prod-desc lulu">
                        Strategery® is a Performance Management Suite of enterprise products the provides it's clients the ability to measure, educate and hire.
                    </div>
                    <div class="prod-released dolo">
                        Aug. 2020
                    </div>
                    <div class="pl-6 mt-8">
                        <v-btn large icon class="cyan--text" style="font-size: 64px;">
                            <i class="las la-arrow-right"></i>
                        </v-btn>
                    </div>
                </div>
                <div class="prod-visual amber">
                    <div class="prod-img">
                        <v-img
                            src="../assets/OctoHeader@4x.png"
                            lazy-src="../assets/OctoHeader@4x.png"
                            contain
                        ></v-img>
                    </div>
                </div>
            </div>
        </div> -->
        <v-dialog v-model="prodDetailDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card color="bgGrey" class="max">
                <dolo-product v-if="prodDets" :prod="prodDets" @killProdDetDialog="killProdDialog()"/>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import doloProduct from '../components/product-detail'

export default {
    components: {
        doloProduct,
    },
    props: {},
    data: () => {
        return {
            prodDets: null,
            prodDetailDialog: false,
            products: [
                {
                    title: 'Strategery',
                    desc: 'Strategery® is a Performance Management Suite of enterprise products the provides it\'s clients the ability to measure, educate and hire.',
                    released: 'Dec. 2020',
                    img: '../assets/stratV2-main@4x.png',
                    color1: 'primary',
                    color2: 'amber',
                    overview: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Diam volutpat commodo sed egestas egestas fringilla phasellus faucibus. In nisl nisi scelerisque eu ultrices vitae auctor eu augue. Sodales neque sodales ut etiam sit amet nisl purus. Aliquet sagittis id consectetur purus ut faucibus. Donec massa sapien faucibus et molestie ac feugiat sed. Tristique magna sit amet purus gravida quis blandit turpis cursus. Etiam dignissim diam quis enim lobortis scelerisque. Nunc sed id semper risus in hendrerit. Et leo duis ut diam quam nulla porttitor massa id. Velit sed ullamcorper morbi tincidunt ornare massa. Mauris sit amet massa vitae tortor condimentum. Morbi tincidunt augue interdum velit euismod in pellentesque massa placerat. Purus semper eget duis at tellus at urna condimentum. Quam viverra orci sagittis eu volutpat.Posuere ac ut consequat semper viverra nam libero justo laoreet. Facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum dui. Sed arcu non odio euismod lacinia at quis risus sed. Nam libero justo laoreet sit amet cursus sit. Tellus in hac habitasse platea. Nam at lectus urna duis convallis convallis tellus. Facilisis sed odio morbi quis commodo. Amet purus gravida quis blandit turpis cursus in hac habitasse. Habitasse platea dictumst vestibulum rhoncus est pellentesque elit ullamcorper dignissim. Urna cursus eget nunc scelerisque viverra mauris in. Vel orci porta non pulvinar neque laoreet.',
                    twitter: '',
                    linkedIn: '',
                    website: 'https://strategery.dololabs.com'
                },
                {
                    title: 'iQ',
                    desc: 'iQ® is a Performance Measurement tool built to integrate with your performance software to track and analyze performance.',
                    released: 'May. 2020',
                    img: '../assets/iq.png',
                    color1: 'amber',
                    color2: 'primary',
                    overview: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Diam volutpat commodo sed egestas egestas fringilla phasellus faucibus. In nisl nisi scelerisque eu ultrices vitae auctor eu augue. Sodales neque sodales ut etiam sit amet nisl purus. Aliquet sagittis id consectetur purus ut faucibus. Donec massa sapien faucibus et molestie ac feugiat sed. Tristique magna sit amet purus gravida quis blandit turpis cursus. Etiam dignissim diam quis enim lobortis scelerisque. Nunc sed id semper risus in hendrerit. Et leo duis ut diam quam nulla porttitor massa id. Velit sed ullamcorper morbi tincidunt ornare massa. Mauris sit amet massa vitae tortor condimentum. Morbi tincidunt augue interdum velit euismod in pellentesque massa placerat. Purus semper eget duis at tellus at urna condimentum. Quam viverra orci sagittis eu volutpat.Posuere ac ut consequat semper viverra nam libero justo laoreet. Facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum dui. Sed arcu non odio euismod lacinia at quis risus sed. Nam libero justo laoreet sit amet cursus sit. Tellus in hac habitasse platea. Nam at lectus urna duis convallis convallis tellus. Facilisis sed odio morbi quis commodo. Amet purus gravida quis blandit turpis cursus in hac habitasse. Habitasse platea dictumst vestibulum rhoncus est pellentesque elit ullamcorper dignissim. Urna cursus eget nunc scelerisque viverra mauris in. Vel orci porta non pulvinar neque laoreet.',
                    twitter: '',
                    linkedIn: '',
                    website: 'https://iq.dololabs.com'
                },
                {
                    title: 'Today',
                    desc: 'Today® is a Dialy and Project Management tool that will allow you to keep track and organize your busy life.',
                    released: 'June. 2020',
                    img: '../assets/today.png',
                    color1: 'pink',
                    color2: 'light-blue',
                    overview: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Diam volutpat commodo sed egestas egestas fringilla phasellus faucibus. In nisl nisi scelerisque eu ultrices vitae auctor eu augue. Sodales neque sodales ut etiam sit amet nisl purus. Aliquet sagittis id consectetur purus ut faucibus. Donec massa sapien faucibus et molestie ac feugiat sed. Tristique magna sit amet purus gravida quis blandit turpis cursus. Etiam dignissim diam quis enim lobortis scelerisque. Nunc sed id semper risus in hendrerit. Et leo duis ut diam quam nulla porttitor massa id. Velit sed ullamcorper morbi tincidunt ornare massa. Mauris sit amet massa vitae tortor condimentum. Morbi tincidunt augue interdum velit euismod in pellentesque massa placerat. Purus semper eget duis at tellus at urna condimentum. Quam viverra orci sagittis eu volutpat.Posuere ac ut consequat semper viverra nam libero justo laoreet. Facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum dui. Sed arcu non odio euismod lacinia at quis risus sed. Nam libero justo laoreet sit amet cursus sit. Tellus in hac habitasse platea. Nam at lectus urna duis convallis convallis tellus. Facilisis sed odio morbi quis commodo. Amet purus gravida quis blandit turpis cursus in hac habitasse. Habitasse platea dictumst vestibulum rhoncus est pellentesque elit ullamcorper dignissim. Urna cursus eget nunc scelerisque viverra mauris in. Vel orci porta non pulvinar neque laoreet.',
                    twitter: '',
                    linkedIn: '',
                    website: 'https://today.dololabs.com'
                },
                {
                    title: 'Avenues',
                    desc: 'Avenues® is a cutting edge trainning system build with access and ROI at it\'s foundation.',
                    released: 'July. 2020',
                    img: '../assets/avenues.png',
                    color1: 'indigo',
                    color2: 'lime',
                    overview: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Diam volutpat commodo sed egestas egestas fringilla phasellus faucibus. In nisl nisi scelerisque eu ultrices vitae auctor eu augue. Sodales neque sodales ut etiam sit amet nisl purus. Aliquet sagittis id consectetur purus ut faucibus. Donec massa sapien faucibus et molestie ac feugiat sed. Tristique magna sit amet purus gravida quis blandit turpis cursus. Etiam dignissim diam quis enim lobortis scelerisque. Nunc sed id semper risus in hendrerit. Et leo duis ut diam quam nulla porttitor massa id. Velit sed ullamcorper morbi tincidunt ornare massa. Mauris sit amet massa vitae tortor condimentum. Morbi tincidunt augue interdum velit euismod in pellentesque massa placerat. Purus semper eget duis at tellus at urna condimentum. Quam viverra orci sagittis eu volutpat.Posuere ac ut consequat semper viverra nam libero justo laoreet. Facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum dui. Sed arcu non odio euismod lacinia at quis risus sed. Nam libero justo laoreet sit amet cursus sit. Tellus in hac habitasse platea. Nam at lectus urna duis convallis convallis tellus. Facilisis sed odio morbi quis commodo. Amet purus gravida quis blandit turpis cursus in hac habitasse. Habitasse platea dictumst vestibulum rhoncus est pellentesque elit ullamcorper dignissim. Urna cursus eget nunc scelerisque viverra mauris in. Vel orci porta non pulvinar neque laoreet.',
                    twitter: '',
                    linkedIn: '',
                    website: 'https://avenues.dololabs.com'
                },
                {
                    title: 'People',
                    desc: 'People® is the new way to recruit and hire talented individuals for your business needs.',
                    released: 'Dec. 2021',
                    img: '../assets/people.png',
                    color1: 'orange',
                    color2: 'cyan',
                    overview: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Diam volutpat commodo sed egestas egestas fringilla phasellus faucibus. In nisl nisi scelerisque eu ultrices vitae auctor eu augue. Sodales neque sodales ut etiam sit amet nisl purus. Aliquet sagittis id consectetur purus ut faucibus. Donec massa sapien faucibus et molestie ac feugiat sed. Tristique magna sit amet purus gravida quis blandit turpis cursus. Etiam dignissim diam quis enim lobortis scelerisque. Nunc sed id semper risus in hendrerit. Et leo duis ut diam quam nulla porttitor massa id. Velit sed ullamcorper morbi tincidunt ornare massa. Mauris sit amet massa vitae tortor condimentum. Morbi tincidunt augue interdum velit euismod in pellentesque massa placerat. Purus semper eget duis at tellus at urna condimentum. Quam viverra orci sagittis eu volutpat.Posuere ac ut consequat semper viverra nam libero justo laoreet. Facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum dui. Sed arcu non odio euismod lacinia at quis risus sed. Nam libero justo laoreet sit amet cursus sit. Tellus in hac habitasse platea. Nam at lectus urna duis convallis convallis tellus. Facilisis sed odio morbi quis commodo. Amet purus gravida quis blandit turpis cursus in hac habitasse. Habitasse platea dictumst vestibulum rhoncus est pellentesque elit ullamcorper dignissim. Urna cursus eget nunc scelerisque viverra mauris in. Vel orci porta non pulvinar neque laoreet.',
                    twitter: '',
                    linkedIn: '',
                    website: 'https://people.dololabs.com'
                },
                {
                    title: 'PiQUE',
                    desc: 'PiQUE is there for any coach that would like to analyze their teams, games and plays.',
                    released: 'July. 2021',
                    img: '../assets/people.png',
                    color1: 'red accent-4',
                    color2: 'indigo',
                    tagline: 'Do what you do, even smarter.',
                    overview: 'PiQUE is an all in one sports platform, aimed at helping coaches and administrators manage organizations. PiQUE can help you with everything from teams, athletes, athlete combine stats, athlete conduct, team rosters, depth charts, game schedules, game planning, scouting opponents, coaching staff, inventory management, offseason programs, custom team site, team fundraising, to in game playcall / result recording with stats and analysis in real-time.',
                    twitter: '',
                    linkedIn: '',
                    website: 'https://pique.dololabs.com'
                }
            ]
        }
    },
    methods: {
        openProdDets(p){
            this.prodDetailDialog = true
            this.prodDets = p
        },
        killProdDialog(){
            this.prodDetailDialog = false
            this.prodDets = null
        }
    }
}
</script>

<style lang="scss" scoped>
    .po-sec1 {
        height: 98vh;
        background-image: url("https://img.rawpixel.com/s3fs-private/rawpixel_images/website_content/k-193-jj-979.jpg?w=1300&dpr=1&fit=default&crop=default&q=80&vib=3&con=3&usm=15&bg=F4F4F3&ixlib=js-2.2.1&s=d22ad91619ef06cac316e1cb28756eaf");
        background-size: cover;
        background-color: #000;
        background-position-y: 75%;
        opacity: 1;

        .pos1-title {
            width: 50%;
            margin-left: 8%;
            margin-bottom: 8.5%;
            font-size: 12em;
            letter-spacing: -8px;
            line-height: 180px
        }
    }

    .po-sec2 {
        padding: 125px 0;

        .prod-card {
            transition: all .54s;
            cursor: pointer;

            .prod-bg {
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                overflow: hidden;

                .cir1 {
                    transition: all .42s;
                    position: absolute;
                    height: 110%;
                    width: 110%;
                    border-radius: 50%;
                    top: -34%;
                    left: 34%;
                }
            }

            .prod-ft {
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                z-index: 1;

                .prod-title {
                    position: absolute;
                    transition: all .54s;
                    bottom: 10%;
                    left: 10%;
                    font-size: 4em;
                }

                .prod-desc {
                    transition: all .54s;
                    opacity: 0;
                    position: absolute;
                    left: 10%;
                    bottom: 20%;
                    width: 65%;
                }

                .prod-released {
                    transition: all .54s;
                    opacity: 0;
                    position: absolute;
                    left: 10%;
                    bottom: 20%;
                }

                .prod-btn {
                    position: absolute;
                    transition: all .54s;
                    bottom: 0;
                    left: 10%;
                    opacity: 0;
                }
            }

            &.active {
                z-index: 2;

                .prod-title {
                    bottom: 70%;
                }

                .prod-btn {
                    transition-delay: .44s;
                    bottom: 10%;
                    opacity: 1;
                }

                .prod-desc {
                    transition-delay: .24s;
                    opacity: 1;
                    bottom: 40%;
                }

                .prod-released {
                    transition-delay: .34s;
                    opacity: 1;
                    left: 10%;
                    bottom: 32%;
                }
            }
        }
    }

    .co-prod {
        position: relative;
        padding: 125px 0;
        min-height: 92vh;

        .prod {
            position: absolute;
            left: 10%;
            top: 20%;
            width: 80%;
            height: 70%;

            .prod-info {
                position: absolute;
                left: 0;
                top: 10%;
                width: 100%;
                z-index: 3;

                .prod-title {
                    font-size: 10em;

                    .regi{
                        font-size: 44px !important;
                    }
                }
                .prod-desc {
                    margin-top: 50px;
                    width: 30%;
                }
                .prod-released {
                    font-size: 16px;
                    margin-top: 200px;
                }
            }

            .prod-visual {
                position: absolute;
                width: 85%;
                height: 100%;
                left: 15%;
                top: 0%;

                .prod-img {
                    position: absolute;
                    top: -10%;
                    right: 0%;
                    width: 60%;
                    height: 90%;
                    z-index: 2;
                    transform: rotate(15deg);
                }
            }
        }

    }

    .tablet {
        .po-sec1 {
            height: 98vh;
            background-image: url("https://img.rawpixel.com/s3fs-private/rawpixel_images/website_content/k-193-eye-0054521.jpg?w=1300&dpr=1&fit=default&crop=default&q=80&vib=3&con=3&usm=15&bg=F4F4F3&ixlib=js-2.2.1&s=fc76753ad411a1a6240eff192487e6af");
            background-size: cover;
            background-color: #000;
            background-position-y: 75%;
            opacity: 1;

            .pos1-title {
                width: 50%;
                margin-left: 8%;
                margin-bottom: 8.5%;
                font-size: 7em;
                letter-spacing: -8px;
                line-height: 90px
            }
        }

        .po-sec2 {
            padding: 125px 0;

            .prod-card {
                transition: all .54s;
                cursor: pointer;

                .prod-ft {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    z-index: 1;

                    .prod-title {
                        position: absolute;
                        transition: all .54s;
                        bottom: 10%;
                        left: 10%;
                        font-size: 2em;
                    }

                    .prod-desc {
                        transition: all .54s;
                        opacity: 0;
                        position: absolute;
                        left: 10%;
                        bottom: 20%;
                        width: 65%;
                    }

                    .prod-released {
                        transition: all .54s;
                        opacity: 0;
                        position: absolute;
                        left: 10%;
                        bottom: 20%;
                    }

                    .prod-btn {
                        position: absolute;
                        transition: all .54s;
                        bottom: 0;
                        left: 10%;
                        opacity: 0;
                    }
                }

                &.active {
                    z-index: 2;

                    .prod-title {
                        bottom: 70%;
                    }

                    .prod-btn {
                        transition-delay: .44s;
                        bottom: 10%;
                        opacity: 1;
                    }

                    .prod-desc {
                        transition-delay: .24s;
                        opacity: 1;
                        bottom: 40%;
                    }

                    .prod-released {
                        transition-delay: .34s;
                        opacity: 1;
                        left: 10%;
                        bottom: 32%;
                    }
                }
            }
        }

        .co-prod {
            position: relative;
            padding: 125px 0;
            min-height: 92vh;

            .prod {
                position: absolute;
                left: 10%;
                top: 20%;
                width: 80%;
                height: 70%;

                .prod-info {
                    position: absolute;
                    left: 0;
                    top: 10%;
                    width: 100%;
                    z-index: 3;

                    .prod-title {
                        font-size: 10em;

                        .regi{
                            font-size: 44px !important;
                        }
                    }
                    .prod-desc {
                        margin-top: 50px;
                        width: 30%;
                    }
                    .prod-released {
                        font-size: 16px;
                        margin-top: 200px;
                    }
                }

                .prod-visual {
                    position: absolute;
                    width: 85%;
                    height: 100%;
                    left: 15%;
                    top: 0%;

                    .prod-img {
                        position: absolute;
                        top: -10%;
                        right: 0%;
                        width: 60%;
                        height: 90%;
                        z-index: 2;
                        transform: rotate(15deg);
                    }
                }
            }

        }
    }

    .mobi {
        .content-inner {
            overflow-x: hidden;
        }
        .po-sec1 {
            height: 98vh;
            background-image: url("https://img.rawpixel.com/s3fs-private/rawpixel_images/website_content/k-193-eye-0054521.jpg?w=1300&dpr=1&fit=default&crop=default&q=80&vib=3&con=3&usm=15&bg=F4F4F3&ixlib=js-2.2.1&s=fc76753ad411a1a6240eff192487e6af");
            background-size: cover;
            background-color: #000;
            background-position-y: 75%;
            opacity: 1;

            .pos1-title {
                width: 50%;
                margin-left: 8%;
                margin-bottom: 12.5%;
                font-size: 5.2em;
                letter-spacing: -8px;
                line-height: 80px
            }
        }

        .po-sec2 {
            padding: 25px 0;

            .prod-card {
                transition: all .54s;
                cursor: pointer;
                border: 1px solid #141414 !important;

                .prod-ft {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    z-index: 1;

                    .prod-title {
                        position: absolute;
                        transition: all .54s;
                        bottom: 10%;
                        left: 10%;
                        font-size: 2em;
                    }

                    .prod-desc {
                        transition: all .54s;
                        opacity: 0;
                        position: absolute;
                        left: 10%;
                        bottom: 20%;
                        width: 65%;
                    }

                    .prod-released {
                        transition: all .54s;
                        opacity: 0;
                        position: absolute;
                        left: 10%;
                        bottom: 20%;
                    }

                    .prod-btn {
                        position: absolute;
                        transition: all .54s;
                        bottom: 0;
                        left: 10%;
                        opacity: 0;
                    }
                }

                &.active {
                    z-index: 2;

                    .prod-title {
                        bottom: 70%;
                    }

                    .prod-btn {
                        transition-delay: .44s;
                        bottom: 10%;
                        opacity: 1;
                    }

                    .prod-desc {
                        transition-delay: .24s;
                        opacity: 1;
                        bottom: 40%;
                    }

                    .prod-released {
                        transition-delay: .34s;
                        opacity: 1;
                        left: 10%;
                        bottom: 32%;
                    }
                }
            }
        }

        .co-prod {
            position: relative;
            padding: 125px 0;
            min-height: 92vh;

            .prod {
                position: absolute;
                left: 10%;
                top: 20%;
                width: 80%;
                height: 70%;

                .prod-info {
                    position: absolute;
                    left: 0;
                    top: 10%;
                    width: 100%;
                    z-index: 3;

                    .prod-title {
                        font-size: 10em;

                        .regi{
                            font-size: 44px !important;
                        }
                    }
                    .prod-desc {
                        margin-top: 50px;
                        width: 30%;
                    }
                    .prod-released {
                        font-size: 16px;
                        margin-top: 200px;
                    }
                }

                .prod-visual {
                    position: absolute;
                    width: 85%;
                    height: 100%;
                    left: 15%;
                    top: 0%;

                    .prod-img {
                        position: absolute;
                        top: -10%;
                        right: 0%;
                        width: 60%;
                        height: 90%;
                        z-index: 2;
                        transform: rotate(15deg);
                    }
                }
            }
        }
    }
</style>