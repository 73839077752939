<template>
    <div data-component="dolo.footer.v1" class="dolo-footer black">
        <v-container>
            <v-layout justify-space-between align-center>
                <v-flex shrink>
                    <v-layout align-center class="t24 white--text">
                        <span class="text-uppercase font-weight-light">
                            <span class="font-weight-black">Dolo</span>Labs
                        </span>
                        <span class="mx-3">
                            <v-card flat height="30px" width="1" color="white"></v-card>
                        </span>
                        <span class="dolo font-weight-bold">
                            Tagline Here
                        </span>
                    </v-layout>
                </v-flex>
                <v-flex shrink>
                    <v-layout row wrap justify-end class="white--text">
                        <v-flex shrink ml-3 v-for="(l, i) in navMenu" :key="i" class="footer-link">
                            <router-link :to="{name: l.to}" class="text-right t16 dolo"> {{l.title}}</router-link>
                        </v-flex>
                    </v-layout>
                    <v-layout row wrap justify-end>
                        <v-flex xs12 class="text-right grey--text text-darken-1">
                            <span class="text-right t12 lulu"> © {{cry}} DoloLabs LLC. All Rights Reserved.</span>
                        </v-flex>
                    </v-layout>
                    <v-layout row wrap justify-end>
                        <v-flex xs12 class="text-right grey--text text-darken-1">
                            <span class="text-right t12 lulu mr-4"> Terms of Service</span>
                            <span class="text-right t12 lulu"> Privacy Policy</span>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
//import moment from 'moment'

export default {
    name: 'doloFooter',
    components: {},
    props: {},
    data: () => {
        return {
            cry: 2020,
            navMenu : [
                {
                    title: 'Hello',
                    subtitle: 'What we are',
                    color: 'tertiary',
                    to: 'home'
                },
                {
                    title: 'About',
                    subtitle: 'Who we are',
                    color: 'tertiary',
                    to: 'about'
                },
                {
                    title: 'Products',
                    subtitle: 'What we\'ve made',
                    color: 'tertiary',
                    to: 'products'
                },
                {
                    title: 'History',
                    subtitle: 'Where we\'ve been',
                    color: 'tertiary',
                    to: 'history'
                },
                {
                    title: 'Careers',
                    subtitle: 'Join the team',
                    color: 'tertiary',
                    to: 'careers'
                },
                {
                    title: 'Contact',
                    subtitle: 'Get in touch',
                    color: 'tertiary',
                    to: 'contact'
                }
            ]
        }
    },
    /* mounted() {
        const a = new Date()
        this.cry = moment(a).format('YYYY')
    } */
}
</script>

<style lang="scss" scoped>
    .dolo-footer {
        position: relative;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 80px 0;

        a {
            cursor: pointer;
            color: #9e9e9e !important;
            text-decoration: none !important;

            &:hover {
                color: #e91e63 !important;
                text-decoration: none !important;
            }
        }
    }
</style>