<template>
    <div data-view="dolo.job-profile.v0.12020" class="max">
        <div class="hidden-xs-only max">
            <div class="jp-sec1">
                <div class="jp-breadcrum">
                    <v-layout align-center fill-height>
                        <span @click="killDialog()" class="dolo t16 white--text" style="cursor: pointer;">About</span>
                        <span class="mx-3 pt-1">
                            <v-card height="1" width="180" color="white"></v-card>
                        </span>
                        <span class="dolo t16 white--text">{{info.title}}</span>
                    </v-layout>
                </div>
                <v-layout fill-height align-end>
                    <div class="jp-header">
                        <div class="jp-name dolo white--text">
                            {{info.title}}
                        </div>
                    </div>
                </v-layout>
            </div>
            <div class="jp-sec2 white black--text">
                <v-container>
                    <v-layout>
                        <v-flex xs12 md4>
                            <v-layout row wrap justify-center>
                                <v-card flat width="250" color="transparent">
                                    <v-card-title>
                                        <v-card flat color="black" height="1" width="250"></v-card>
                                    </v-card-title>
                                    <v-card-text class="dolo black--text t20 font-weight-black" style="">
                                        Overview.
                                    </v-card-text>
                                </v-card>
                            </v-layout>
                        </v-flex>
                        <v-flex xs12 md8 class="right-section pr-0">
                            <div class="t20 lulu" style="line-height: 41.5px; margin-bottom: 60px;">
                                {{info.overview}}
                            </div>
                            <div class="dolo black--text t20 font-weight-black mb-10">
                                Preferences:
                            </div>
                            <div v-for="(p,i) in info.liketosees" :key="i" class="mb-8">
                                <v-layout>
                                    <v-flex shrink pt-2>
                                        <span class="mr-2 black--text">
                                            <i class="lar la-circle black--text" style="font-size: 14px"></i>
                                        </span>
                                    </v-flex>
                                    <v-flex>
                                        <span class="lulu t20 mr-2" style="line-height: 41.5px">
                                            {{p}}
                                        </span>
                                    </v-flex>
                                </v-layout>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-container>
            </div>
        </div>
        <div class="mobi hidden-sm-and-up">
            <div class="jp-sec1">
                <div class="jp-breadcrum text-center">
                    <v-btn text class="t24 white--text" @click="killDialog()">
                        <i class="las la-times"></i>
                    </v-btn>
                </div>
                <v-layout fill-height align-end>
                    <div class="jp-header">
                        <div class="jp-name dolo white--text">
                            {{info.title}}
                        </div>
                    </div>
                </v-layout>
            </div>
            <div class="jp-sec2 white black--text">
                <v-container>
                    <v-layout row wrap>
                        <v-flex xs12 md4 pl-8>
                            <v-layout row wrap>
                                <v-card flat color="transparent">
                                    <v-card-title>
                                        <v-card flat color="black" height="1" width="150"></v-card>
                                    </v-card-title>
                                    <v-card-text class="dolo black--text t18 font-weight-black" style="">
                                        Overview.
                                    </v-card-text>
                                </v-card>
                            </v-layout>
                        </v-flex>
                        <v-flex xs12 class="right-section px-8">
                            <div class="t14 lulu" style="line-height: 31.5px; margin-bottom: 60px;">
                                {{info.overview}}
                            </div>
                            <div class="dolo black--text t18 font-weight-black mb-10">
                                Preferences:
                            </div>
                            <div v-for="(p,i) in info.liketosees" :key="i" class="mb-8">
                                <v-layout>
                                    <v-flex shrink pt-2>
                                        <span class="mr-2 black--text">
                                            <i class="lar la-circle black--text" style="font-size: 10px"></i>
                                        </span>
                                    </v-flex>
                                    <v-flex>
                                        <span class="lulu t14 mr-2" style="line-height: 31.5px">
                                            {{p}}
                                        </span>
                                    </v-flex>
                                </v-layout>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-container>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        info: Object,
    },
    data: () => {
        return {

        }
    },
    methods: {
        killDialog(){
            this.$emit('killCareerDialog')
        }
    }
}
</script>

<style lang="scss" scoped>
    .jp-sec1 {
        height: 75vh;
        /* background: rgb(239,239,239);
        background: linear-gradient(331deg, rgba(239,239,239,1) 8%, rgba(146,148,154,1) 100%); */
        background: rgb(173,20,87);
        background: linear-gradient(331deg, rgba(173,20,87,1) 0%, rgba(26,35,126,1) 100%);
        overflow: hidden;

        .jp-header {
            width: 50%;
            margin-left: 8%;
            margin-bottom: 2.5%;

            .jp-name {
                font-size: 8em;
                letter-spacing: -2.5px;
            }
            .jp-pos {
                font-size: 1.5em;
                margin: -60px 0 20px;
            }
        }

        .jp-breadcrum{
            position: absolute;
            left: 8%;
            top: 2%;
            height: 50px;
        }
    }

    .jp-sec2 {
        padding: 125px 0;

        .right-section {
            padding: 0 0% 
        }

        .abs2-title {
            font-size: 3.5em;
            line-height: 70px;
            word-wrap: break-word;
        }
    }

    .tablet {
        .jp-sec1 {
            height: 75vh;
            /* background: rgb(239,239,239);
            background: linear-gradient(331deg, rgba(239,239,239,1) 8%, rgba(146,148,154,1) 100%); */
            background: rgb(173,20,87);
            background: linear-gradient(331deg, rgba(173,20,87,1) 0%, rgba(26,35,126,1) 100%);
            overflow: hidden;

            .jp-header {
                width: 50%;
                margin-left: 8%;
                margin-bottom: 2.5%;

                .jp-name {
                    font-size: 5em;
                    letter-spacing: -2.5px;
                }
                .jp-pos {
                    font-size: 1.5em;
                    margin: -60px 0 20px;
                }
            }

            .jp-breadcrum{
                position: absolute;
                left: 8%;
                top: 2%;
                height: 50px;
            }
        }

        .jp-sec2 {
            padding: 125px 0;

            .right-section {
                padding: 0 0% 
            }

            .abs2-title {
                font-size: 3.5em;
                line-height: 70px;
                word-wrap: break-word;
            }
        }
    }

    .mobi {
       .jp-sec1 {
            height: 75vh;
            background: rgb(173,20,87);
            background: linear-gradient(331deg, rgba(173,20,87,1) 0%, rgba(26,35,126,1) 100%);
            overflow: hidden;

            .jp-header {
                width: 100%;
                margin-left: 8%;
                margin-bottom: 12.5%;

                .jp-name {
                    font-size: 5em;
                    letter-spacing: -2.5px;
                    line-height: 70px;
                }
                .jp-pos {
                    font-size: 12px;
                    margin: -20px 0 20px;
                }
            }

            .jp-breadcrum{
                position: absolute;
                left: 0%;
                width: 100%;
                top: 2%;
                height: 50px;
            }
        }

        .jp-sec2 {
            padding: 25px 0;

            .abs2-title {
                font-size: 1.5em;
                line-height: 50px;
                word-wrap: break-word;
            }
        } 
    }
</style>