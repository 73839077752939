<template>
    <div data-view="dolo.careers.v0.12020" class="content-inner max white">
        <div class="hidden-xs-only">
            <div class="c-sec1">
                <v-layout fill-height align-end>
                    <div class="cs1-title dolo font-weight-black white--text">
                        Careers.
                    </div>
                </v-layout>
            </div>
            <div class="c-sec2 white">
                <v-container>
                    <v-layout>
                        <v-flex xs12 md4>
                            <v-card flat>
                                <v-card-title class="px-0">
                                    <v-card flat color="black" height="1" width="250"></v-card>
                                </v-card-title>
                                <v-card-text class="dolo t20 black--text font-weight-black px-0" style="">
                                    Why Dolo Labs?
                                </v-card-text>
                            </v-card>
                        </v-flex>
                        <v-flex xs12 md8 class="right-section pr-0">
                            <div class="cs2-title lulu mb-12">
                                What would the world be like if everyone was capable of making their ideas a professional priority?
                            </div>
                            <div class="t18 lulu">
                                At Dolo Labs, ideas are our life blood. Without we would be wondering in the darkness trying to play darts.
                            </div>
                        </v-flex>
                    </v-layout>
                </v-container>
            </div>
            <div class="c-sec3 black white--text">
                <v-container>
                    <v-layout>
                        <v-flex xs12 md4>
                            <v-card flat color="transparent">
                                <v-card-title class="px-0">
                                    <v-card flat color="white" height="1" width="250"></v-card>
                                </v-card-title>
                                <v-card-text class="dolo t20 white--text font-weight-black px-0" style="">
                                    The Dolo Standard.
                                </v-card-text>
                            </v-card>
                        </v-flex>
                        <v-flex xs12 md8 class="right-section pr-0">
                            <div v-for="(s,i) in standards" :key="i" style="margin-bottom: 90px">
                                <div class="cs3-title dolo mb-2">
                                    {{s.title}}
                                </div>
                                <div class="t20 lulu">
                                    {{s.desc}}
                                </div>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-container>
            </div>
            <div class="c-sec4 white white--text">
                <v-container>
                    <v-layout row wrap>
                        <v-flex xs12 md4 mb-12>
                            <v-layout row wrap>
                                <v-card flat width="250" color="transparent">
                                    <v-card-title>
                                        <v-card flat color="black" height="2" width="250"></v-card>
                                    </v-card-title>
                                    <v-card-text class="dolo black--text t20 font-weight-black" style="">
                                        Current Openings.
                                    </v-card-text>
                                </v-card>
                            </v-layout>
                        </v-flex>
                        <v-flex xs12 md8>
                            <v-layout row wrap v-if="openJobs.length > 0">
                                <v-flex xs12 pt-12 v-for="(p, i) in openJobs" :key="i" style="margin-bottom: 200px;">
                                    <div class="cs4-title black--text dolo mb-12">
                                        {{p.location}}.
                                    </div>
                                    <v-layout row wrap>
                                        <v-flex xs12 md6 pr-12 mb-6 v-for="(j,i) in p.openings" :key="i">
                                            <div @click="openDoloCareer(j)" class="job-card mr-12">
                                                <v-card-title class="job-title pb-0 lulu t18 pt-12">
                                                    {{j.title}}
                                                </v-card-title>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap v-else pl-12 ml-12>
                                <v-card flat color="transparent">
                                    <v-card-text>
                                        <div class="t32 lulu mb-12 primary--text">Sorry, No Current Openings.</div>
                                    </v-card-text>
                                    <v-card-text>
                                        <div class="lulu t18 black--text">We would still love to hear from you.<br> Send us an email at work@dololabs.com and tell us a little bit about yourself.</div>
                                    </v-card-text>
                                </v-card>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-container>
            </div>
        </div>
        <div class="hidden-sm-and-up mobi">
            <div class="c-sec1">
                <v-layout fill-height align-end>
                    <div class="cs1-title dolo font-weight-black white--text">
                        Careers.
                    </div>
                </v-layout>
            </div>
            <div class="c-sec2 white">
                <v-container>
                    <v-layout row wrap>
                        <v-flex xs12 pl-8 mb-12>
                            <v-card flat>
                                <v-card-title class="px-0">
                                    <v-card flat color="black" height="1" width="150"></v-card>
                                </v-card-title>
                                <v-card-text class="dolo t18 black--text font-weight-black px-0" style="">
                                    Why Dolo Labs?
                                </v-card-text>
                            </v-card>
                        </v-flex>
                        <v-flex xs12 md8 class="right-section pr-8">
                            <div class="cs2-title lulu mb-12">
                                What would the world be like if everyone was capable of making their ideas a professional priority?
                            </div>
                            <div class="t14 lulu">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu felis bibendum ut tristique 
                                et egestas quis ipsum. Orci a scelerisque purus semper eget duis at. Ipsum dolor sit amet consectetur adipiscing elit ut aliquam. Vulputate enim nulla aliquet 
                                porttitor lacus luctus. Morbi quis commodo odio aenean. Consequat interdum varius sit amet mattis. Auctor elit sed vulputate mi sit amet. Arcu odio ut sem nulla 
                                pharetra diam. Velit aliquet sagittis id consectetur purus ut faucibus. Congue mauris rhoncus aenean vel elit scelerisque. Pulvinar mattis nunc sed blandit libero 
                                volutpat sed cras. Eu augue ut lectus arcu.
                            </div>
                        </v-flex>
                    </v-layout>
                </v-container>
            </div>
            <div class="c-sec3 black white--text">
                <v-container>
                    <v-layout row wrap>
                        <v-flex xs12 pl-8 mb-12>
                            <v-card flat color="transparent">
                                <v-card-title class="px-0">
                                    <v-card flat color="white" height="1" width="150"></v-card>
                                </v-card-title>
                                <v-card-text class="dolo t18 white--text font-weight-black px-0" style="">
                                    The Dolo Standard.
                                </v-card-text>
                            </v-card>
                        </v-flex>
                        <v-flex xs12 class="right-section pr-8">
                            <div v-for="(s,i) in standards" :key="i" style="margin-bottom: 45px">
                                <div class="cs3-title dolo mb-2">
                                    {{s.title}}
                                </div>
                                <div class="t14 lulu">
                                    {{s.desc}}
                                </div>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-container>
            </div>
            <div class="c-sec4 white white--text">
                <v-container>
                    <v-layout row wrap>
                        <v-flex xs12 mb-12>
                            <v-layout row wrap pl-8>
                                <v-card flat width="250" color="transparent">
                                    <v-card-title>
                                        <v-card flat color="black" height="2" width="150"></v-card>
                                    </v-card-title>
                                    <v-card-text class="dolo black--text t18 font-weight-black" style="">
                                        Current Openings.
                                    </v-card-text>
                                </v-card>
                            </v-layout>
                        </v-flex>
                        <v-flex xs12 md8>
                            <v-layout row wrap px-12 v-if="openJobs.length > 0">
                                <v-flex xs12 v-for="(p, i) in openJobs" :key="i" style="margin-bottom: 100px;">
                                    <div class="cs4-title black--text dolo mb-6">
                                        {{p.location}}.
                                    </div>
                                    <v-layout row wrap>
                                        <v-flex xs12  mb-3 v-for="(j,i) in p.openings" :key="i">
                                            <div @click="openDoloCareer(j)" class="job-card">
                                                <v-card-title class="job-title pb-0 lulu t18 pt-12">
                                                    {{j.title}}
                                                </v-card-title>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap px-12>
                                <div class="cs2-title lulu mb-12">No Current Openings.</div>
                                <div class="lulu t18 mb-8">We would still love to hear from you. Send us an email at work@dololabs.com and tell us a little about yourself.</div>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-container>
            </div>
        </div>
        <v-dialog v-model="posDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card v-if="posInfo" color="bgGrey" class="max">
                <job-profile :info="posInfo" @killCareerDialog="killCareerDialog()" />
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import jobProfile from '../components/job-details'

export default {
    components: {
        jobProfile
    },
    props: {},
    data: () => {
        return {
            posDialog: false,
            posInfo: null,
            standards: [
                {
                    title: 'Be a badass.',
                    desc: 'Don\'t be affraid to say what you think. Strong teams are built on different perspectives.'
                },
                {
                    title: 'Make it.',
                    desc: 'If you can think it ... we will make it.'
                },
                {
                    title: 'Be efficient.',
                    desc: 'We don\'t waste time on anything, meetings, sillyness ... okay, maybe some shinanigans is ok.'
                },
                {
                    title: 'Have fun.',
                    desc: 'This should be a job you miss when you\'re on vacation.'
                },
                {
                    title: 'Get it done.',
                    desc: 'Project timelines are what they are. Work when you want to but get the job done on time.'
                }
            ],
            openJobs: [
                /* {
                    location: 'Dallas',
                    openings: [
                        {
                            title: 'Position Title',
                            overview: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nulla facilisi etiam dignissim diam quis enim. Convallis tellus id interdum velit laoreet id donec. Arcu odio ut sem nulla pharetra diam. Sagittis nisl rhoncus mattis rhoncus urna neque viverra justo. Orci a scelerisque purus semper. In nulla posuere sollicitudin aliquam ultrices. Senectus et netus et malesuada fames ac. Lacus suspendisse faucibus interdum posuere lorem ipsum dolor sit amet. Lectus urna duis convallis convallis tellus. Hendrerit dolor magna eget est lorem ipsum. Sapien nec sagittis aliquam malesuada bibendum arcu vitae elementum. Purus in mollis nunc sed id semper risus in hendrerit. Duis at tellus at urna condimentum mattis pellentesque id nibh. Eget mi proin sed libero. Ac tortor vitae purus faucibus ornare suspendisse. Lobortis feugiat vivamus at augue. Integer feugiat scelerisque varius morbi enim nunc faucibus.',
                            liketosees: [
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                            ],
                            jNumber: '#D0-1051955'

                        },
                        {
                            title: 'Position Title',
                            overview: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nulla facilisi etiam dignissim diam quis enim. Convallis tellus id interdum velit laoreet id donec. Arcu odio ut sem nulla pharetra diam. Sagittis nisl rhoncus mattis rhoncus urna neque viverra justo. Orci a scelerisque purus semper. In nulla posuere sollicitudin aliquam ultrices. Senectus et netus et malesuada fames ac. Lacus suspendisse faucibus interdum posuere lorem ipsum dolor sit amet. Lectus urna duis convallis convallis tellus. Hendrerit dolor magna eget est lorem ipsum. Sapien nec sagittis aliquam malesuada bibendum arcu vitae elementum. Purus in mollis nunc sed id semper risus in hendrerit. Duis at tellus at urna condimentum mattis pellentesque id nibh. Eget mi proin sed libero. Ac tortor vitae purus faucibus ornare suspendisse. Lobortis feugiat vivamus at augue. Integer feugiat scelerisque varius morbi enim nunc faucibus.',
                            liketosees: [
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                            ],
                            jNumber: '#D0-1071982'

                        },
                        {
                            title: 'Position Title',
                            overview: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nulla facilisi etiam dignissim diam quis enim. Convallis tellus id interdum velit laoreet id donec. Arcu odio ut sem nulla pharetra diam. Sagittis nisl rhoncus mattis rhoncus urna neque viverra justo. Orci a scelerisque purus semper. In nulla posuere sollicitudin aliquam ultrices. Senectus et netus et malesuada fames ac. Lacus suspendisse faucibus interdum posuere lorem ipsum dolor sit amet. Lectus urna duis convallis convallis tellus. Hendrerit dolor magna eget est lorem ipsum. Sapien nec sagittis aliquam malesuada bibendum arcu vitae elementum. Purus in mollis nunc sed id semper risus in hendrerit. Duis at tellus at urna condimentum mattis pellentesque id nibh. Eget mi proin sed libero. Ac tortor vitae purus faucibus ornare suspendisse. Lobortis feugiat vivamus at augue. Integer feugiat scelerisque varius morbi enim nunc faucibus.',
                            liketosees: [
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                            ],
                            jNumber: '#D0-02261996'

                        },
                        {
                            title: 'Position Title',
                            overview: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nulla facilisi etiam dignissim diam quis enim. Convallis tellus id interdum velit laoreet id donec. Arcu odio ut sem nulla pharetra diam. Sagittis nisl rhoncus mattis rhoncus urna neque viverra justo. Orci a scelerisque purus semper. In nulla posuere sollicitudin aliquam ultrices. Senectus et netus et malesuada fames ac. Lacus suspendisse faucibus interdum posuere lorem ipsum dolor sit amet. Lectus urna duis convallis convallis tellus. Hendrerit dolor magna eget est lorem ipsum. Sapien nec sagittis aliquam malesuada bibendum arcu vitae elementum. Purus in mollis nunc sed id semper risus in hendrerit. Duis at tellus at urna condimentum mattis pellentesque id nibh. Eget mi proin sed libero. Ac tortor vitae purus faucibus ornare suspendisse. Lobortis feugiat vivamus at augue. Integer feugiat scelerisque varius morbi enim nunc faucibus.',
                            liketosees: [
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                            ],
                            jNumber: '#D0-03011977'

                        },
                    ]
                },
                {
                    location: 'Remote',
                    openings: [
                        {
                            title: 'Position Title',
                            overview: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nulla facilisi etiam dignissim diam quis enim. Convallis tellus id interdum velit laoreet id donec. Arcu odio ut sem nulla pharetra diam. Sagittis nisl rhoncus mattis rhoncus urna neque viverra justo. Orci a scelerisque purus semper. In nulla posuere sollicitudin aliquam ultrices. Senectus et netus et malesuada fames ac. Lacus suspendisse faucibus interdum posuere lorem ipsum dolor sit amet. Lectus urna duis convallis convallis tellus. Hendrerit dolor magna eget est lorem ipsum. Sapien nec sagittis aliquam malesuada bibendum arcu vitae elementum. Purus in mollis nunc sed id semper risus in hendrerit. Duis at tellus at urna condimentum mattis pellentesque id nibh. Eget mi proin sed libero. Ac tortor vitae purus faucibus ornare suspendisse. Lobortis feugiat vivamus at augue. Integer feugiat scelerisque varius morbi enim nunc faucibus.',
                            liketosees: [
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                            ],
                            jNumber: '#D0-12032007'

                        },
                        {
                            title: 'Position Title',
                            overview: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nulla facilisi etiam dignissim diam quis enim. Convallis tellus id interdum velit laoreet id donec. Arcu odio ut sem nulla pharetra diam. Sagittis nisl rhoncus mattis rhoncus urna neque viverra justo. Orci a scelerisque purus semper. In nulla posuere sollicitudin aliquam ultrices. Senectus et netus et malesuada fames ac. Lacus suspendisse faucibus interdum posuere lorem ipsum dolor sit amet. Lectus urna duis convallis convallis tellus. Hendrerit dolor magna eget est lorem ipsum. Sapien nec sagittis aliquam malesuada bibendum arcu vitae elementum. Purus in mollis nunc sed id semper risus in hendrerit. Duis at tellus at urna condimentum mattis pellentesque id nibh. Eget mi proin sed libero. Ac tortor vitae purus faucibus ornare suspendisse. Lobortis feugiat vivamus at augue. Integer feugiat scelerisque varius morbi enim nunc faucibus.',
                            liketosees: [
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                            ],
                            jNumber: '#D0-12092006'

                        },
                        {
                            title: 'Position Title',
                            overview: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nulla facilisi etiam dignissim diam quis enim. Convallis tellus id interdum velit laoreet id donec. Arcu odio ut sem nulla pharetra diam. Sagittis nisl rhoncus mattis rhoncus urna neque viverra justo. Orci a scelerisque purus semper. In nulla posuere sollicitudin aliquam ultrices. Senectus et netus et malesuada fames ac. Lacus suspendisse faucibus interdum posuere lorem ipsum dolor sit amet. Lectus urna duis convallis convallis tellus. Hendrerit dolor magna eget est lorem ipsum. Sapien nec sagittis aliquam malesuada bibendum arcu vitae elementum. Purus in mollis nunc sed id semper risus in hendrerit. Duis at tellus at urna condimentum mattis pellentesque id nibh. Eget mi proin sed libero. Ac tortor vitae purus faucibus ornare suspendisse. Lobortis feugiat vivamus at augue. Integer feugiat scelerisque varius morbi enim nunc faucibus.',
                            liketosees: [
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                            ],
                            jNumber: '#D0-12041979'

                        }
                    ]
                }, */
            ]
        }
    },
    methods: {
        openDoloCareer(l) {
            this.posDialog = true
            this.posInfo = l
        },
        killCareerDialog() {
            this.posDialog = false
            this.posInfo = null
        }
    }
}
</script>

<style lang="scss" scoped>
    .c-sec1 {
        height: 75vh;
        background-image: url("https://img.rawpixel.com/s3fs-private/rawpixel_images/website_content/-a010-markuss-0303.jpg?w=1300&dpr=1&fit=default&crop=default&q=80&vib=3&con=3&usm=15&bg=F4F4F3&ixlib=js-2.2.1&s=139b88eb7ecdc01218d0b7b8839e7b88");
        background-size: cover;
        background-color: #000;
        background-position-y: 75%;
        opacity: 1;

        .cs1-title {
            width: 50%;
            margin-left: 8%;
            margin-bottom: 2.5%;
            font-size: 12em;
            letter-spacing: -8px;
            line-height: 180px
        }
    }

    .c-sec2 {
        padding: 125px 0;

        .right-section {
            padding: 2% 8% 0
        }

        .cs2-title {
            font-size: 3.5em;
            line-height: 70px;
            word-wrap: break-word;
        }
    }

    .c-sec3 {
        padding: 125px 0;

        .right-section {
            padding: 2% 8% 0
        }

        .cs3-title {
            font-size: 5.5em;
            line-height: 70px;
            word-wrap: break-word;
        }
    }

    .c-sec4 {
        padding: 125px 0;

        .right-section {
            padding: 2% 8% 0
        }

        .cs4-title {
            font-size: 5.5em;
            line-height: 70px;
            word-wrap: break-word;
        }

        .job-card {
            border-bottom: 1px solid #000 !important;
            height: 120px;
            cursor: pointer;

            .job-title {
                color: #000000;
            }

            &:hover {
                .job-title {
                    color: #FFC107;
                }
            }
        }

        
    }

    .tablet {
        .c-sec1 {
            height: 75vh;
            background-image: url("https://img.rawpixel.com/s3fs-private/rawpixel_images/website_content/-a010-markuss-0303.jpg?w=1300&dpr=1&fit=default&crop=default&q=80&vib=3&con=3&usm=15&bg=F4F4F3&ixlib=js-2.2.1&s=139b88eb7ecdc01218d0b7b8839e7b88");
            background-size: cover;
            background-color: #000;
            background-position-y: 75%;
            opacity: 1;

            .cs1-title {
                width: 50%;
                margin-left: 8%;
                margin-bottom: 2.5%;
                font-size: 8em;
                letter-spacing: -8px;
                line-height: 180px
            }
        }

        .c-sec2 {
            padding: 125px 0;

            .right-section {
                padding: 2% 8% 0
            }

            .cs2-title {
                font-size: 2.5em;
                line-height: 70px;
                word-wrap: break-word;
            }
        }

        .c-sec3 {
            padding: 125px 0;

            .right-section {
                padding: 2% 8% 0
            }

            .cs3-title {
                font-size: 3.5em;
                line-height: 70px;
                word-wrap: break-word;
            }
        }

        .c-sec4 {
            padding: 125px 0;

            .right-section {
                padding: 2% 8% 0
            }

            .cs4-title {
                font-size: 5.5em;
                line-height: 70px;
                word-wrap: break-word;
            }

            .job-card {
                border-bottom: 1px solid #000 !important;
                height: 120px;
                cursor: pointer;

                .job-title {
                    color: #000000;
                }

                &:hover {
                    .job-title {
                        color: #FFC107;
                    }
                }
            }

            
        }
    }

    .mobi {
        .c-sec1 {
            height: 75vh;
            background-image: url("https://img.rawpixel.com/s3fs-private/rawpixel_images/website_content/-a010-markuss-0303.jpg?w=1300&dpr=1&fit=default&crop=default&q=80&vib=3&con=3&usm=15&bg=F4F4F3&ixlib=js-2.2.1&s=139b88eb7ecdc01218d0b7b8839e7b88");
            background-size: cover;
            background-color: #000;
            background-position-y: 75%;
            background-position-x: 45%;
            opacity: 1;

            .cs1-title {
                width: 100%;
                margin-left: 5%;
                margin-bottom: 5.5%;
                font-size: 4em;
                letter-spacing: -2.5px;
                line-height: 80px
            }
        }

        .c-sec2 {
            padding: 25px 0;

            .cs2-title {
                font-size: 1.5em;
                line-height: 35px;
                word-wrap: break-word;
            }
        }

        .c-sec3 {
            padding: 25px 0;

            .cs3-title {
                font-size: 2.5em;
                line-height: 40px;
                word-wrap: break-word;
            }
        }

        .c-sec4 {
            padding: 25px 0;

            .cs4-title {
                font-size: 2.5em;
                line-height: 70px;
                word-wrap: break-word;
            }

            .job-card {
                border-bottom: 1px solid #000 !important;
                height: 120px;
                cursor: pointer;

                .job-title {
                    color: #000000;
                }

                &:hover {
                    .job-title {
                        color: #FFC107;
                    }
                }
            }

            
        }
    }
</style>