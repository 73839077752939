<template>
    <div data-view="dolo.mobi-lead-profile.v0.12020" class="max mobi">
        <div class="lp-sec1">
            <div class="lp-breadcrum text-center">
                <v-btn text class="t24 white--text" @click="killDialog()">
                    <i class="las la-times"></i>
                </v-btn>
            </div>
            <div class="lp-img">
                <!-- <v-img
                    :src="info.img"
                    :lazy-src="info.img"
                    aspect-ratio="1"
                    class="grey lighten-2"
                ></v-img> -->
            </div>
            <v-layout fill-height align-end>
                <div class="lp-header">
                    <div class="lp-name dolo white--text">
                        {{info.displayName}}
                    </div>
                    <div class="lp-pos lulu white--text">
                        {{info.title}}
                    </div>
                    <div class="lp-social">
                        <v-btn fab outlined x-small color="white" class="mr-3" :href="info.twitter">
                            <i class="lab la-twitter" style="font-size: 12px"></i>
                        </v-btn>
                        <v-btn fab outlined x-small color="white" class="mr-3" :href="info.linkedin">
                            <i class="lab la-linkedin-in" style="font-size: 12px"></i>
                        </v-btn>
                        <v-btn fab outlined x-small color="white" class="mr-3" href="mailto:jayohhink@gmail.com">
                            <i class="lab la-telegram" style="font-size: 12px"></i>
                        </v-btn>
                    </div>
                </div>
            </v-layout>
        </div>
        <div class="lp-sec2 white black--text">
            <v-container>
                <v-layout row wrap px-6>
                    <v-flex xs12 mb-6>
                        <v-layout row wrap pl-4>
                            <v-card flat  color="transparent">
                                <v-card-title class="pl-0">
                                    <v-card flat color="black" height="1" width="150"></v-card>
                                </v-card-title>
                                <v-card-text class="dolo black--text t20 font-weight-black pl-0" style="">
                                    Biography.
                                </v-card-text>
                            </v-card>
                        </v-layout>
                    </v-flex>
                    <v-flex xs12 class="right-section pr-0">
                        <div class="t14 lulu" style="line-height: 31.5px">
                            {{info.bio}}
                        </div>
                    </v-flex>
                </v-layout>
            </v-container>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        info: Object,
    },
    data: () => {
        return {

        }
    },
    methods: {
        killDialog(){
            this.$emit('killLPdialog')
            //eslint-disable-next-line
            console.log('mobi-lp.methods.killDialog')
        }
    }
}
</script>

<style lang="scss" scoped>
    .mobi {
        .lp-sec1 {
            height: 75vh;
            background: rgb(173,20,87);
            background: linear-gradient(331deg, rgba(173,20,87,1) 0%, rgba(26,35,126,1) 100%);
            overflow: hidden;

            .lp-img {
                position: absolute;
                left: 0%;
                height: 100%;
                width: 100%;
                top: 0%;
            }

            .lp-header {
                width: 100%;
                margin-left: 6%;
                margin-bottom: 8.5%;
                z-index: 2;

                .lp-name {
                    font-size: 4em;
                    letter-spacing: -2.5px;
                }
                .lp-pos {
                    font-size: 12px;
                    margin: -20px 0 20px;
                }
            }

            .lp-breadcrum{
                position: absolute;
                left: 0%;
                top: 2%;
                height: 50px;
                width: 100%;
                z-index: 5;
            }
        }

        .lp-sec2 {
            padding: 25px 0;

            .right-section {
                padding: 0 0% 
            }

            .abs2-title {
                font-size: 3.5em;
                line-height: 70px;
                word-wrap: break-word;
            }
        }
    }
</style>