import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

const vuetify = new Vuetify({
    theme: {
      themes: {
        light: {
          primary: '#000000',
          secondary: '#009688',
          tertiary: '#FFC107',
          accent: '#efefef',
          urgent: '#ef5350',
          substandard: '#f89c1c',
          meetsEx: '#864c9b',
          proficient: '#475f89',
          exceptional: '#68c563',
          bgGrey: '#f3f3f3'
        },
      },
    },
  })
  
  export default {
    vuetify
  }
