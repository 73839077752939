<template>
    <div data-view="dolo.home.v0.12020" class="content-inner max white">
        <v-window v-model="homeSection" class="max hidden-xs-only" vertical>
            <div class="hsnb-container">
                <v-layout class="fill-height align-center justify-center">
                    <v-item-group
                        v-model="homeSection"
                        class="shrink mr-6"
                        mandatory
                        tag="v-flex"
                    >
                        <v-item
                            v-for="n in homeSectionTotal"
                            :key="n"
                            v-slot:default="{ active, toggle }"
                            class="my-2"
                        >
                            <div>
                                <v-btn
                                    :input-value="active"
                                    icon
                                    @click="toggle"
                                >
                                    <v-icon :color="homeSection == n - 1 ? 'secondary' : 'bgGrey darken-1'">mdi-record</v-icon>
                                </v-btn>
                            </div>
                        </v-item>
                    </v-item-group>
                </v-layout>
            </div>
            <div class="dolo-overlay" :class="homeSection > 0 ? 'show' : ''">
                <div class="text-center">
                    <v-card flat height="2" width="40" class="mb-4" color="black"></v-card>
                </div>
                <div class="dolo-text">
                    <div class="t22 dolo text-uppercase black--text">
                        <span>Dolo</span>
                        <span>Labs.</span>
                    </div>
                    <div class="t12 lulu text-uppercase">Est. 2018</div>
                </div>
                <span></span>
            </div>
            <div class="dolo-window-btns" :class="homeSection > 0 ? 'show' : ''">
                <v-btn class="pa-6 mr-4" depressed outlined color="white" @click="lastSection()"><i class="las la-arrow-up t32"></i></v-btn>
                <v-btn class="pa-6 mr-4" depressed outlined color="white" @click="nextSection()"><i class="las la-arrow-down t32"></i></v-btn>
            </div>
            <v-window-item class="h1-sec max">
                <v-layout row wrap fill-height justify-center align-center>
                    <v-flex xs12 justify-center>
                        <div class="hss1-subtitle lulu mb-2k text-center">Here at Dolo Labs we are:</div>
                        <div class="hss1-title dolo text-center" @click="hsTo()">
                            <span class="typed ml-3 black--text">{{ typeValue }}</span>
                            <span class="cursor" :class="{typing: typeStatus}">&nbsp;</span> 
                        </div>
                    </v-flex>
                </v-layout>
                <!-- <div class="hswa" @click="hsTo()">
                    <v-layout justify-center align-center class="lulu font-weight-light indigo--text">
                        <span>We are</span>
                        <span class="typed ml-3 lime--text">{{ typeValue }}</span>
                        <span class="cursor" :class="{typing: typeStatus}">&nbsp;</span> 
                    </v-layout>
                </div> -->
                <div class="line" :class="'step'+homeSection">
                    <v-layout justify-center align-center>
                        <v-btn icon @click="nextSection()">
                            <i class="las la-arrow-down secondary--text" style="font-size: 64px"></i>
                        </v-btn>
                    </v-layout>
                </div>
            </v-window-item>
            <v-window-item v-for="(w, i) in ideals" :key="i" class="h1-sec max" :class="[homeSection == i + 1 ? 'active' : '', w.bgColor]">
                <div class="hss2-title">
                    <div class="dolo mb-10" :class="w.textColor+'--text'">{{w.title}}</div>
                    <v-card flat height="2" width="25%" :color="w.textColor"></v-card>
                    <div class="sec-text t18 lulu mt-10" :class="w.textColor+'--text'">
                        {{w.info}}
                    </div>
                </div>
            </v-window-item>
        </v-window>
        <v-window v-model="homeSection" class="max hidden-sm-and-up">
            <div class="hsnb-container">
                <v-layout class="justify-center">
                    <v-item-group
                        v-model="homeSection"
                        class="shrink"
                        mandatory
                        tag="v-flex"
                    >
                        <v-item
                            v-for="n in homeSectionTotal"
                            :key="n"
                            v-slot:default="{ active, toggle }"
                            class="mx-0"
                        >
                            <v-btn
                                :input-value="active"
                                icon
                                small
                                @click="toggle"
                            >
                                <v-icon :color="homeSection == n - 1 ? 'secondary' : 'bgGrey darken-1'" size="12">mdi-record</v-icon>
                            </v-btn>
                        </v-item>
                    </v-item-group>
                </v-layout>
            </div>
            <div class="dolo-overlay" :class="homeSection > 0 ? 'show' : ''">
                <div class="text-center pl-4">
                    <v-card flat height="150" width="3" class="mb-6" color="black"></v-card>
                </div>
                <div class="dolo-text mt-n12" style="transform: rotate(90deg)">
                    <div class="t22 dolo text-uppercase">
                        <span>Dolo</span>
                        <span>Labs</span>
                    </div>
                    <div class="t14 lulu text-uppercase">Est. 2018</div>
                </div>
                <span></span>
            </div>
            <v-window-item class="h1-sec max">
                <v-layout row wrap fill-height justify-center align-center>
                    <v-flex xs12 justify-center>
                        <div class="hss1-subtitle lulu mb-2k text-center">Here at Dolo Labs we are:</div>
                        <div class="hss1-title dolo text-center" @click="hsTo()">
                            <span class="typed ml-3 black--text">{{ typeValue }}</span>
                            <span class="cursor" :class="{typing: typeStatus}">&nbsp;</span> 
                        </div>
                    </v-flex>
                </v-layout>
            </v-window-item>
            <v-window-item v-for="(w, i) in ideals" :key="i" class="h1-sec max" :class="[homeSection == i + 1 ? 'active' : '', w.bgColor]">
                <div class="hss2-title">
                    <div class="dolo mb-4" :class="w.textColor+'--text'">{{w.title}}</div>
                    <v-card flat height="2" width="25%" :color="w.textColor"></v-card>
                    <div class="sec-text lulu mt-10" :class="w.textColor+'--text'">
                        {{w.info}}
                    </div>
                </div>
            </v-window-item>
        </v-window>
    </div>
</template>

<script>

export default {
    name: 'Home',
    components: {
    },
    props: {},
    mounted() {
        this.homeSection = 0
    },
    data: () => {
        return {
            typewriteLink: 1,
            homeSection: 0,
            homeSectionTotal: 10,

            typeValue: '',
            typeStatus: false,
            typeArray: ['an idea factory.', 'creators.', 'disruptors.', 'collaborators.', 'efficient.', 'other.', 'not waiting.', 'next.', 'Dolo.'],
            typingSpeed: 150,
            erasingSpeed: 100,
            newTextDelay: 2000,
            typeArrayIndex: 0,
            charIndex: 0,
            ideals: [
                {
                    title: 'Idea Factory.',
                    bgColor: 'secondary',
                    textColor: 'white',
                    info: 'Ideas are great but being capable of turning those ideas into tagible monitized products is where the magic is. We never want to live in the paradigm of what is, we strive for what could be.'
                },
                {
                    title: 'Creators.',
                    bgColor: 'tertiary',
                    textColor: 'white',
                    info: 'If you can think it we can create it.'
                },
                {
                    title: 'Distruptors.',
                    bgColor: 'grey',
                    textColor: 'white',
                    info: 'Status Quo is not our bag. Not becuase we want to be rebelious but because foward is the only direction we were given. If we are not moving forward, what are we doing? When there is a problem we aim to solve it. There are to restraints put on the solution other then let the solution be the solution. We don\'t concern ourselves with what is currently out there, we ask ourselves "how would we do it" and then make that. '
                },
                {
                    title: 'Collaborators.',
                    bgColor: 'black',
                    textColor: 'white',
                    info: 'We are a team, and not a team in the sense that we all work for Dolo Labs but in a way that we all own our products together. With that in mind all opinions and options are on the table. We\'re not pixel pushers, ticket gobblers or administrators, we are owners, and together we find the best solutiion.'
                },
                {
                    title: 'Efficient.',
                    bgColor: 'secondary',
                    textColor: 'white',
                    info: 'Our process is efficient mainly becuase we have one. We don\'t process becuase someone wrote a book about it or it\'s the new awesome thing, which frankly is just the same thing as everything else with a different name. We are just organized, if we fail then we fail fast, learn and then make a better product the next time.'
                },
                {
                    title: 'Other.',
                    bgColor: 'tertiary',
                    textColor: 'white',
                    info: 'We are the "other" option. Not just for the sake of being different but becuase it\'s just how we are wired. We are the soar thumbs that stick out in the crowd, the wild cards, the exception to the rule. It\'s time that there\'s a company out there that embraces our differences rather than being affraid of them. We are the band of misfits, and that is what makes us great! '
                },
                {
                    title: 'Not Waiting.',
                    bgColor: 'grey',
                    textColor: 'white',
                    info: 'We are tired of waiting, we are going to make it and we are going to make it now. We aren\'t intereted in standing in line an waiting for our turn, because we don\'t have to. '
                },
                {
                    title: 'Next.',
                    bgColor: 'secondary',
                    textColor: 'white',
                    info: 'We are the next big thing. We have the best people, our goal is to create, and we are better at it then they are.'
                },
                {
                    title: 'Dolo.',
                    bgColor: 'black',
                    textColor: 'white',
                    info: 'Do "it" on your own, but do it together. Be creative, orginal, passionate, unrelenting and efficient. With those credentials it doesn\'t matter what "it" is, because "it" will be the right solution to the right problem. '
                }
            ]
        }
    },
    methods: {
        typeText() {
            if(this.charIndex < this.typeArray[this.typeArrayIndex].length) {
            if(!this.typeStatus)
                this.typeStatus = true;
            this.typeValue += this.typeArray[this.typeArrayIndex].charAt(this.charIndex);
            this.charIndex += 1;
            setTimeout(this.typeText, this.typingSpeed);
            }
            else {
            this.typeStatus = false;
            setTimeout(this.eraseText, this.newTextDelay);
            }
        },
        eraseText() {
            if(this.charIndex > 0) {
            if(!this.typeStatus)
                this.typeStatus = true;
            this.typeValue = this.typeArray[this.typeArrayIndex].substring(0, this.charIndex - 1);
            this.charIndex -= 1;
            setTimeout(this.eraseText, this.erasingSpeed);
            }
            else {
            this.typeStatus = false;
            this.typeArrayIndex += 1;
            if(this.typeArrayIndex >= this.typeArray.length)
                this.typeArrayIndex = 0;
            setTimeout(this.typeText, this.typingSpeed + 1000);
            }
        },
        hsTo() {
            this.homeSection = this.typeArrayIndex + 1
        },
        nextSection(){
            this.homeSection = this.homeSection + 1
        },
        lastSection(){
            this.homeSection = this.homeSection - 1
        }
    },
    created() {
        setTimeout(this.typeText, this.newTextDelay + 200);
    }
}
</script>

<style lang="scss" scoped>
    .h1-sec {
        .hss1-title {
            font-size: 12em;
            letter-spacing: -15.5px;

            .cursor {
                display: inline-block;
                margin-left: 30px;
                width: 4px;
                background-color: #FFC107;
                min-height: 180px;
                animation: cursorBlink .95s infinite;

                &.typing {
                    animation: none;
                }
            }
        }

        .hss1-subtitle {
            font-size: 40px;
        }

        .hss2-title {
            position: absolute;
            margin-left: 0%;
            top: 28%;
            opacity: 0;
            transition: all 2s;
            font-size: 10em;
            z-index: 2;
            width: 50%;
            line-height: 140px;

            .sec-text {
                opacity: 0;
                font-size: 18px;
            }
        }

        &.active {
            .hss2-title {
                opacity: 1;
                left: 5%;
                bottom: 10%;
            } 

            .sec-text {
                opacity: 1;
                font-size: 18px;
                line-height: normal;
            }
        }

    }

    .hswa {
        z-index: 1;
        color: black;
        font-size: 3em;

        /* .cursor {
            display: inline-block;
            margin-left: 10px;
            width: 4px;
            background-color: #CDDC39;
            min-height: 90px;
            animation: cursorBlink .95s infinite;

            &.typing {
                animation: none;
            }
        } */
    }

    .dolo-overlay {
        position: fixed;
        left: 100px;
        top: -40px;
        z-index: 3;
        opacity: 0;
        transition: all .62s;

        &.show {
            top: 60px;
            opacity: 1;
            transition-delay: .5s
        }
    }

    .dolo-window-btns{
        bottom: -50px;
        opacity: 0;
        position: fixed;
        right: 50px;
        transition: all .62s;
        z-index: 3;

        &.show {
            bottom: 50px;
            opacity: 1;
            transition-delay: .5s
        }
    }

    .line {
        position: absolute;
        bottom: 50px;
        width: 100%;
        left: 0;
        z-index: 1;
        animation: none;

        &.step0 {
            -webkit-animation: bounce 2s infinite;
            animation: bounce 2s infinite;
        }
    }

    .hsnb-container {
        position: absolute;
        right: 0;
        height: 100vh;
        top: 0;
        z-index: 3;
    }

    .tablet {
        .dolo-overlay {
            display: none;
        }
        .h1-sec {
            .hss1-title {
                font-size: 7.2em;
                letter-spacing: -15.5px;

                .cursor {
                    display: inline-block;
                    margin-left: 30px;
                    width: 4px;
                    background-color: #FFC107;
                    min-height: 110px;
                    animation: cursorBlink .95s infinite;

                    &.typing {
                        animation: none;
                    }
                }
            }

            .hss1-subtitle {
                font-size: 24px;
            }

            .hss2-title {
                position: absolute;
                margin-left: 0%;
                top: 31%;
                opacity: 0;
                transition: all 2s;
                font-size: 6em;
                z-index: 2;
                width: 50%;
                line-height: 80px;

                .sec-text {
                    opacity: 0;
                    font-size: 18px;
                }
            }

            &.active {
                .hss2-title {
                    opacity: 1;
                    left: 14%;
                    bottom: 10%;
                } 

                .sec-text {
                    opacity: 1;
                    font-size: 18px;
                    line-height: normal;
                }
            }
        }
        .line {
            position: absolute;
            bottom: 120px;
            width: 100%;
            left: 0;
            z-index: 1;
            animation: none;

            &.step0 {
                -webkit-animation: bounce 2s infinite;
                animation: bounce 2s infinite;
            }
        }
    }

    .mobi {
        .h1-sec {
            overflow: hidden;

            .hss1-title {
                font-size: 36px;
                letter-spacing: -2.5px;

                .cursor {
                    display: inline-block;
                    margin-left: 10px;
                    width: 4px;
                    background-color: #FFC107;
                    min-height: 40px;
                    animation: cursorBlink .95s infinite;

                    &.typing {
                        animation: none;
                    }
                }
            }

            .hss1-subtitle {
                font-size: 16px;
            }

            .hss2-title {
                position: absolute;
                margin-left: 0%;
                top: 30%;
                opacity: 0;
                transition: all 2s;
                font-size: 2em;
                z-index: 2;
                width: 70%;
                line-height: 40px;

                .sec-text {
                    opacity: 0;
                    font-size: 12px;
                }
            }

            &.active {
                .hss2-title {
                    opacity: 1;
                    left: 14%;
                    bottom: 10%;
                } 

                .sec-text {
                    opacity: 1;
                    font-size: 12px;
                    line-height: normal;
                }
            }

        }

        .hswa {
            z-index: 1;
            color: black;
            font-size: 3em;
        }

        .dolo-overlay {
            position: fixed;
            left: 100px;
            top: -40px;
            z-index: 3;
            opacity: 0;
            transition: all .62s;

            &.show {
                top: 0;
                opacity: 1;
                transition-delay: .5s
            }
        }

        .hsnb-container {
            position: absolute;
            left: 0px;
            width: 100%;
            top: 90%;
            z-index: 3;
            height: 50px;
            white-space: nowrap;
        }
    }

    @keyframes cursorBlink {
        49% {background-color: #FFC107;}
        50% {background-color: transparent;}
        99% {background-color: transparent;}
    }

    .bounce {
        -webkit-animation: bounce 2s infinite;
        animation: bounce 2s infinite;
    }

    /* Scroll down indicator (bouncing) */
    @-webkit-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -webkit-transform: translateY(0); }
    40% {
        -webkit-transform: translateY(-30px); }
    60% {
        -webkit-transform: translateY(-15px); } }
    @-moz-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -moz-transform: translateY(0); }
    40% {
        -moz-transform: translateY(-30px); }
    60% {
        -moz-transform: translateY(-15px); } }
    @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0); }
    40% {
        -webkit-transform: translateY(-30px);
        -moz-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
        -o-transform: translateY(-30px);
        transform: translateY(-30px); }
    60% {
        -webkit-transform: translateY(-15px);
        -moz-transform: translateY(-15px);
        -ms-transform: translateY(-15px);
        -o-transform: translateY(-15px);
        transform: translateY(-15px); } 
        }
</style>