<template>
    <div data-view="dolo.lead-profile.v0.12020" class="max">
        <div class="max hidden-xs-only">
            <div class="lp-sec1">
                <div class="lp-breadcrum">
                    <v-layout align-center fill-height>
                        <span @click="killDialog()" class="dolo t16 white--text" style="cursor: pointer;">About</span>
                        <span class="mx-3 pt-1">
                            <v-card height="1" width="180" color="white"></v-card>
                        </span>
                        <span class="dolo t16 white--text">{{info.name}}</span>
                    </v-layout>
                </div>
                <div class="lp-img">
                    <v-img
                        :src="info.img"
                        :lazy-src="info.img"
                        aspect-ratio="1"
                        class="grey lighten-2"
                    ></v-img>
                </div>
                <v-layout fill-height align-end>
                    <div class="lp-header">
                        <div class="lp-name dolo white--text">
                            {{info.displayName}}
                        </div>
                        <div class="lp-pos lulu white--text">
                            {{info.title}}
                        </div>
                        <div class="lp-social">
                            <v-btn fab outlined color="white" class="mr-3" :href="info.twitter">
                                <i class="lab la-twitter" style="font-size: 24px"></i>
                            </v-btn>
                            <v-btn fab outlined color="white" class="mr-3" :href="info.linkedin">
                                <i class="lab la-linkedin-in" style="font-size: 24px"></i>
                            </v-btn>
                            <v-btn fab outlined color="white" class="mr-3" href="mailto:jayohhink@gmail.com">
                                <i class="lab la-telegram" style="font-size: 24px"></i>
                            </v-btn>
                        </div>
                    </div>
                </v-layout>
            </div>
            <div class="lp-sec2 white black--text">
                <v-container>
                    <v-layout>
                        <v-flex xs12 md4>
                            <v-layout row wrap justify-center>
                                <v-card flat width="250" color="transparent">
                                    <v-card-title>
                                        <v-card flat color="black" height="1" width="250"></v-card>
                                    </v-card-title>
                                    <v-card-text class="dolo black--text t20 font-weight-black" style="">
                                        Biography.
                                    </v-card-text>
                                </v-card>
                            </v-layout>
                        </v-flex>
                        <v-flex xs12 md8 class="right-section pr-0">
                            <div class="t20 lulu" style="line-height: 41.5px">
                                {{info.bio}}
                            </div>
                        </v-flex>
                    </v-layout>
                </v-container>
            </div>
        </div>
        <div class="max hidden-sm-and-up">
            <mobi-lp :info="info" @killLPdialog="killDialog()" />
        </div>
    </div>
</template>

<script>
import mobiLp from './mobile/mobi-leadership-profile'

export default {
    components: {
        mobiLp
    },
    props: {
        info: Object,
    },
    data: () => {
        return {

        }
    },
    methods: {
        killDialog(){
            this.$emit('killLPdialog')
        }
    }
}
</script>

<style lang="scss" scoped>
    .lp-sec1 {
        height: 75vh;
        /* background: rgb(239,239,239);
        background: linear-gradient(331deg, rgba(239,239,239,1) 8%, rgba(146,148,154,1) 100%); */
        background: rgb(173,20,87);
        background: linear-gradient(331deg, rgba(173,20,87,1) 0%, rgba(26,35,126,1) 100%);
        overflow: hidden;

        .lp-img {
            position: absolute;
            left: 45%;
            height: 65%;
            width: 40%;
            top: 10%;
        }

        .lp-header {
            width: 50%;
            margin-left: 8%;
            margin-bottom: 2.5%;

            .lp-name {
                font-size: 12em;
                letter-spacing: -8px;
            }
            .lp-pos {
                font-size: 1.5em;
                margin: -60px 0 20px;
            }
        }

        .lp-breadcrum{
            position: absolute;
            left: 8%;
            top: 2%;
            height: 50px;
        }
    }

    .lp-sec2 {
        padding: 125px 0;

        .right-section {
            padding: 0 0% 
        }

        .abs2-title {
            font-size: 3.5em;
            line-height: 70px;
            word-wrap: break-word;
        }
    }

    .tablet {
        .lp-sec1 {
            height: 75vh;
            /* background: rgb(239,239,239);
            background: linear-gradient(331deg, rgba(239,239,239,1) 8%, rgba(146,148,154,1) 100%); */
            background: rgb(173,20,87);
            background: linear-gradient(331deg, rgba(173,20,87,1) 0%, rgba(26,35,126,1) 100%);
            overflow: hidden;

            .lp-img {
                position: absolute;
                left: 55%;
                height: 65%;
                width: 40%;
                top: 20%;
            }

            .lp-header {
                width: 50%;
                margin-left: 8%;
                margin-bottom: 2.5%;

                .lp-name {
                    font-size: 8.2em;
                    letter-spacing: -8px;
                }
                .lp-pos {
                    font-size: 1em;
                    margin: -40px 0 20px;
                }
            }

            .lp-breadcrum{
                position: absolute;
                left: 8%;
                top: 2%;
                height: 50px;
            }
        }

        .lp-sec2 {
            padding: 125px 0;

            .right-section {
                padding: 0 0% 
            }

            .abs2-title {
                font-size: 3.5em;
                line-height: 70px;
                word-wrap: break-word;
            }
        }
    }
</style>