import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

/* import vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css' */
import '../stylesheets/global.scss'

import vuetify from './plugins/vuetify';

import Vuetify from 'vuetify/lib';

Vue.config.productionTip = false

const opts = {
  theme: {
      dark: false,
      themes: {
          light: {
            primary: '#000000',
            secondary: '#009688',
            tertiary: '#FBC02D',
            accent: '#efefef',
            urgent: '#ef5350',
            substandard: '#f89c1c',
            meetsEx: '#864c9b',
            proficient: '#475f89',
            exceptional: '#68c563',
            bgGrey: '#f3f3f3'
          },
          dark: {
            primary: '#000000',
            secondary: '#009688',
            tertiary: '#FBC02D',
            accent: '#efefef',
            urgent: '#ef5350',
            substandard: '#f89c1c',
            meetsEx: '#864c9b',
            proficient: '#475f89',
            exceptional: '#68c563',
            bgGrey: '#f3f3f3'
          }
      }
  }
}

//plugins
Vue.use(
  vuetify,
  //require('vue-moment')
  //moment,
)

new Vue({
  router,
  store,
  //vuetify,

  vuetify: new Vuetify(opts),
  render: h => h(App)
}).$mount('#app')
