<template>
    <div data-component="dolo.mobi-footer.v1" class="dolo-footer black">
        <v-container>
            <v-layout row wrap justify-center align-center>
                <v-flex xs12>
                    <div class="t16 text-center white--text">
                        <span class="text-uppercase font-weight-light">
                            <span class="font-weight-black">Dolo</span>Labs
                        </span>
                    </div>
                </v-flex><!-- 
                <v-flex xs12>
                    <div class="t12 white--text">
                        <span class="dolo font-weight-bold">
                            Tagline Here
                        </span>
                    </div>
                </v-flex> -->
                <v-flex xs12>
                    <div class="text-center grey--text text-darken-1">
                        <span class="text-right t8 lulu"> © {{cry}} DoloLabs LLC. All Rights Reserved.</span>
                    </div>
                </v-flex>
                <v-flex xs12>
                    <div class="text-center grey--text text-darken-1">
                        <span class="text-right t8 lulu mr-4"> Terms of Service</span>
                        <span class="text-right t8 lulu"> Privacy Policy</span>
                    </div>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
//import moment from 'moment'

export default {
    name: 'doloFooter',
    components: {},
    props: {},
    data: () => {
        return {
            cry: 2020,
        }
    },
    /* mounted() {
        const a = new Date()
        this.cry = moment(a).format('YYYY')
    } */
}
</script>

<style lang="scss" scoped>
    .dolo-footer {
        position: relative;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 20px 0;

        a {
            cursor: pointer;
            color: #9e9e9e !important;
            text-decoration: none !important;

            &:hover {
                color: #e91e63 !important;
                text-decoration: none !important;
            }
        }
    }
</style>