<template>
    <div data-view="dolo.about.v0.12020" class="content-inner max white">
        <div class="max hidden-xs-only">
            <div class="ab-sec1">
                <v-layout fill-height align-end>
                    <div class="abs1-title dolo font-weight-black white--text">
                        Born <br> in Dallas.
                    </div>
                </v-layout>
            </div>
            <div class="ab-sec2 white">
                <v-container>
                    <v-layout>
                        <v-flex xs12 md4>
                            <v-card flat>
                                <v-card-title class="px-0">
                                    <v-card flat color="black" height="1" width="250"></v-card>
                                </v-card-title>
                                <v-card-text class="dolo t20 black--text font-weight-black px-0" style="">
                                    About Us.
                                </v-card-text>
                            </v-card>
                        </v-flex>
                        <v-flex xs12 md8 class="right-section pr-0">
                            <div class="abs2-title lulu mb-12">
                                We turn dreams into reality through action.
                            </div>
                            <div class="t18 lulu">
                                Dreams and ideas are worth nothing if you can't turn them into something tangible. Dolo Labs is the place that strives to innovate
                                through action, drive and determination.<br><br>

                                We believe that the essense of perfection is simplicity. The simpler tools are, the more perfect they become. This belief drives us
                                to create instruments that are uncomplicated yet wide-ranging and wildy effective.
                            </div>
                        </v-flex>
                    </v-layout>
                </v-container>
            </div>
            <div class="ab-sec2 black white--text">
                <v-container>
                    <v-layout>
                        <v-flex xs12 md4>
                            <v-card flat color="transparent">
                                <v-card-title class="px-0">
                                    <v-card flat color="white" height="1" width="250"></v-card>
                                </v-card-title>
                                <v-card-text class="dolo t20 white--text font-weight-black px-0" style="">
                                    Our Vision.
                                </v-card-text>
                            </v-card>
                        </v-flex>
                        <v-flex xs12 md8 class="right-section pr-0">
                            <div class="abs2-title lulu mb-12">
                                Don't just build different, be different.
                            </div>
                            <div class="t18 lulu">
                                Dolo Labs doesn't just focus on distrupting the industries that we create for, but also create a new way
                                to work, manage and business.<br><br>

                                We're all partners in the process of creating.
                            </div>
                        </v-flex>
                    </v-layout>
                    <!-- <v-layout row wrap>
                        <v-flex xs12 mb-12>
                            <v-layout row wrap>
                                <v-card flat width="250" color="transparent">
                                    <v-card-title>
                                        <v-card flat color="white" height="1" width="250"></v-card>
                                    </v-card-title>
                                    <v-card-text class="dolo white--text t20 font-weight-black" style="">
                                        Our Leadership.
                                    </v-card-text>
                                </v-card>
                            </v-layout>
                        </v-flex>
                        <v-flex xs12 mt-12>
                            <v-layout row wrap>
                                <v-flex xs12 md4 px-3 mb-8 v-for="(l, i) in leadership" :key="i">
                                    <div @click="openDoloProfile(l)" style="border-bottom: 1px solid #fff !important; height: 150px; cursor: pointer">
                                        <v-card-title class="pb-0 dolo white--text lead-name">
                                            {{l.name}}
                                        </v-card-title>
                                        <v-card-title class="pt-0 mb6 lulu grey--text lead-title">
                                            {{l.title}}
                                        </v-card-title>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout> -->
                </v-container>
            </div>
        </div>
        <div class="max  hidden-sm-and-up">
            <div class="ab-sec1">
                <v-layout fill-height align-end>
                    <div class="abs1-title dolo font-weight-black white--text">
                        Born <br> in Dallas.
                    </div>
                </v-layout>
            </div>
            <div class="ab-sec2 white">
                <v-container>
                    <v-layout row wrap>
                        <v-flex xs12 mb-12 pl-2>
                            <v-card flat>
                                <v-card-title class="">
                                    <v-card flat color="black" height="1" width="150"></v-card>
                                </v-card-title>
                                <v-card-text class="dolo t16 black--text font-weight-black" style="">
                                    About Us.
                                </v-card-text>
                            </v-card>
                        </v-flex>
                        <v-flex xs12 class="right-section pr-0">
                            <div class="abs2-title lulu mb-6">
                                We grow business by creating experiences people love.
                            </div>
                            <div class="t14 lulu pr-4">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu felis bibendum ut tristique 
                                et egestas quis ipsum. Orci a scelerisque purus semper eget duis at. Ipsum dolor sit amet consectetur adipiscing elit ut aliquam. Vulputate enim nulla aliquet 
                                porttitor lacus luctus. Morbi quis commodo odio aenean. Consequat interdum varius sit amet mattis. Auctor elit sed vulputate mi sit amet. Arcu odio ut sem nulla 
                                pharetra diam. Velit aliquet sagittis id consectetur purus ut faucibus. Congue mauris rhoncus aenean vel elit scelerisque. Pulvinar mattis nunc sed blandit libero 
                                volutpat sed cras. Eu augue ut lectus arcu.
                            </div>
                        </v-flex>
                    </v-layout>
                </v-container>
            </div>
            <div class="ab-sec2 black white--text">
                <v-container>
                    <v-layout row wrap>
                        <v-flex xs12 mb-6 pl-4>
                            <v-layout row wrap>
                                <v-card flat color="transparent">
                                    <v-card-title>
                                        <v-card flat color="white" height="1" width="150"></v-card>
                                    </v-card-title>
                                    <v-card-text class="dolo white--text t16 font-weight-black" style="">
                                        Our Leadership.
                                    </v-card-text>
                                </v-card>
                            </v-layout>
                        </v-flex>
                        <v-flex xs12 mt-6 px-5>
                            <v-layout row wrap px-3>
                                <v-flex xs12 mb-4 v-for="(l, i) in leadership" :key="i">
                                    <div @click="openDoloProfile(l)" style="border-bottom: 1px solid #fff !important; height: 115px; cursor: pointer">
                                        <v-card-title class="pb-0 dolo white--text lead-name pl-1">
                                            {{l.name}}
                                        </v-card-title>
                                        <v-card-title class="pt-0 mb6 lulu grey--text lead-title pl-1">
                                            {{l.title}}
                                        </v-card-title>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-container>
            </div>
        </div>
        <v-dialog v-model="dlDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card v-if="dlInfo" color="bgGrey" class="max">
                <leadership-profile :info="dlInfo" @killLPdialog="killLpDialog()"/>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import leadershipProfile from '../components/leadership-profile'

export default {
    components: {
        leadershipProfile,
    },
    props: {},
    data: () => {
        return {
            dlDialog: false,
            dlInfo: null,
            leadership: [
                {
                    name: 'Josh Olson',
                    title: 'Founder & CEO',
                    displayName: 'Josh.',
                    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9JTQ0VSqJngSKfNVoY6bzcVTeTwLsUO8x2xRv3uqDnyzJyfb7&s',
                    linkedIn: '',
                    twitter: '',
                    email: 'jolson@dololabs.com',
                    bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu felis bibendum ut tristique et egestas quis ipsum. Orci a scelerisque purus semper eget duis at. Ipsum dolor sit amet consectetur adipiscing elit ut aliquam. Vulputate enim nulla aliquet porttitor lacus luctus. Morbi quis commodo odio aenean. Consequat interdum varius sit amet mattis. Auctor elit sed vulputate mi sit amet. Arcu odio ut sem nulla pharetra diam. Velit aliquet sagittis id consectetur purus ut faucibus. Congue mauris rhoncus aenean vel elit scelerisque. Pulvinar mattis nunc sed blandit libero volutpat sed cras. Eu augue ut lectus arcu.'
                },
                {
                    name: 'Bevin Thomas',
                    title: 'VP of Technology & Implementation',
                    displayName: 'Bevin.',
                    img: 'https://cdn.imgbin.com/15/24/20/imgbin-dylan-o-brien-teen-wolf-stiles-stilinski-wondercon-others-814aZ5b5speJJAg3SeZ8pvKcW.jpg',
                    linkedIn: '',
                    twitter: '',
                    email: 'bthomas@dololabs.com',
                    bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu felis bibendum ut tristique et egestas quis ipsum. Orci a scelerisque purus semper eget duis at. Ipsum dolor sit amet consectetur adipiscing elit ut aliquam. Vulputate enim nulla aliquet porttitor lacus luctus. Morbi quis commodo odio aenean. Consequat interdum varius sit amet mattis. Auctor elit sed vulputate mi sit amet. Arcu odio ut sem nulla pharetra diam. Velit aliquet sagittis id consectetur purus ut faucibus. Congue mauris rhoncus aenean vel elit scelerisque. Pulvinar mattis nunc sed blandit libero volutpat sed cras. Eu augue ut lectus arcu.'
                },
                {
                    name: 'Lulu Reed',
                    title: 'VP of Product Development',
                    displayName: 'Lu.',
                    img: 'https://i7.pngguru.com/preview/992/399/696/professional-woman-photography-woman.jpg',
                    linkedIn: '',
                    twitter: '',
                    email: 'lreed@dololabs.com',
                    bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu felis bibendum ut tristique et egestas quis ipsum. Orci a scelerisque purus semper eget duis at. Ipsum dolor sit amet consectetur adipiscing elit ut aliquam. Vulputate enim nulla aliquet porttitor lacus luctus. Morbi quis commodo odio aenean. Consequat interdum varius sit amet mattis. Auctor elit sed vulputate mi sit amet. Arcu odio ut sem nulla pharetra diam. Velit aliquet sagittis id consectetur purus ut faucibus. Congue mauris rhoncus aenean vel elit scelerisque. Pulvinar mattis nunc sed blandit libero volutpat sed cras. Eu augue ut lectus arcu.'
                },

                {
                    name: 'Douglas Christopher',
                    title: 'Chief Strategery Officer',
                    displayName: 'Doug.',
                    img: 'https://cdn.imgbin.com/15/24/20/imgbin-dylan-o-brien-teen-wolf-stiles-stilinski-wondercon-others-814aZ5b5speJJAg3SeZ8pvKcW.jpg',
                    linkedIn: '',
                    twitter: '',
                    email: 'bthomas@dololabs.com',
                    bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu felis bibendum ut tristique et egestas quis ipsum. Orci a scelerisque purus semper eget duis at. Ipsum dolor sit amet consectetur adipiscing elit ut aliquam. Vulputate enim nulla aliquet porttitor lacus luctus. Morbi quis commodo odio aenean. Consequat interdum varius sit amet mattis. Auctor elit sed vulputate mi sit amet. Arcu odio ut sem nulla pharetra diam. Velit aliquet sagittis id consectetur purus ut faucibus. Congue mauris rhoncus aenean vel elit scelerisque. Pulvinar mattis nunc sed blandit libero volutpat sed cras. Eu augue ut lectus arcu.'
                }
            ]
        }
    },
    methods: {
        openDoloProfile(l) {
            this.dlDialog = true
            this.dlInfo = l
        },
        killLpDialog() {
            this.dlDialog = false
            this.dlInfo = null
        }
    }
}
</script>

<style lang="scss" scoped>
    .ab-sec1 {
        height: 75vh;
        //background-image: url("https://www.uponarriving.com/wp-content/uploads/2016/05/Dallas-Skyline_-3-1-820x410.jpg");
        //background-image: url("https://q-cf.bstatic.com/images/hotel/max1024x768/213/213737033.jpg");
        background-image: url("https://www.pixelstalk.net/wp-content/uploads/images1/Dallas-Wallpapers-HD-Free-download.jpg");
        background-size: cover;
        background-color: #000;
        background-position-y: 75%;
        opacity: 1;

        .abs1-title {
            width: 50%;
            margin-left: 8%;
            margin-bottom: 2.5%;
            font-size: 12em;
            letter-spacing: -8px;
            line-height: 180px
        }
    }

    .ab-sec2 {
        padding: 125px 0;

        .right-section {
            padding: 0 8% 
        }

        .abs2-title {
            font-size: 3.5em;
            line-height: 70px;
            word-wrap: break-word;
        }

        .lead-name {
            font-size: 28px;
        }

        .lead-title {
            font-size: 20px
        }
    }

    .tablet {
        .ab-sec1 {
            height: 75vh;
            //background-image: url("https://www.uponarriving.com/wp-content/uploads/2016/05/Dallas-Skyline_-3-1-820x410.jpg");
            //background-image: url("https://q-cf.bstatic.com/images/hotel/max1024x768/213/213737033.jpg");
            background-image: url("https://www.pixelstalk.net/wp-content/uploads/images1/Dallas-Wallpapers-HD-Free-download.jpg");
            background-size: cover;
            background-color: #000;
            background-position-y: 75%;
            opacity: 1;

            .abs1-title {
                width: 50%;
                margin-left: 8%;
                margin-bottom: 2.5%;
                font-size: 7em;
                letter-spacing: -8px;
                line-height: 90px
            }
        }

        .ab-sec2 {
            padding: 125px 0;

            .right-section {
                padding: 0 8% 
            }

            .abs2-title {
                font-size: 2.5em;
                line-height: 70px;
                word-wrap: break-word;
            }

            .lead-name {
                font-size: 22px;
            }

            .lead-title {
                font-size: 16px
            }
        }
    }

    .mobi {
        .ab-sec1 {
            height: 75vh;
            //background-image: url("https://www.uponarriving.com/wp-content/uploads/2016/05/Dallas-Skyline_-3-1-820x410.jpg");
            //background-image: url("https://q-cf.bstatic.com/images/hotel/max1024x768/213/213737033.jpg");
            background-image: url("https://www.pixelstalk.net/wp-content/uploads/images1/Dallas-Wallpapers-HD-Free-download.jpg");
            background-size: cover;
            background-color: #000;
            background-position-y: 45%;
            opacity: 1;

            .abs1-title {
                width: 90%;
                margin-left: 5%;
                margin-bottom: 2.5%;
                font-size: 3.5em;
                letter-spacing: -2.5px;
                line-height: 50px
            }
        }

        .ab-sec2 {
            padding: 25px 0;

            .abs2-title {
                font-size: 1.5em;
                line-height: 30px;
                word-wrap: break-word;
            }

            .lead-name {
                font-size: 18px;
            }

            .lead-title {
                font-size: 12px
            }
        }
    }
</style>